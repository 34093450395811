import { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { auth } from '../../service/service'
import { sendEmailVerification } from 'firebase/auth'
import { styled } from '@mui/material/styles'

const Form = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}))
const Paper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const actionCodeSettings = {
  url: 'https://dressboard.work/',
  // url: 'http://localhost:3000/',
  handleCodeInApp: false,
}

function Wrapper(props: any) {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper>
        <Typography component="h1" variant="h5">
          メールアドレス認証を行う
        </Typography>
        <Form noValidate>{props.children}</Form>
      </Paper>
    </Container>
  )
}
const SubmitButton = styled(Button)`
  backgroundColor: "#DBD1E8",
  color: "black",
  margin: theme.spacing(3, 0, 2),
`

function ResetButton(props: any) {
  return (
    <SubmitButton
      fullWidth
      variant="contained"
      onClick={props.onClick}
      style={{ marginTop: 16, backgroundColor: '#DBD1E8', color: 'black' }}
    >
      メール送信
    </SubmitButton>
  )
}

function SentPaper() {
  return (
    <>
      <Typography variant="body1" align="center">
        メールを送信しました。
      </Typography>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Button color="primary">トップへ</Button>
      </Link>
    </>
  )
}

function MailVertificationScreen() {
  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)

  useEffect(() => {
    // ユーザーがログインしているか確認し、ログインしていればメールアドレスを取得して設定する
    if (auth.currentUser) {
      setEmail(auth.currentUser!.email!)
    }
  }, [])

  const sendMail = (e: any) => {
    e.preventDefault()
    console.log('sendMail start')
    sendEmailVerification(auth.currentUser!, actionCodeSettings)
      .then(() => {
        console.log('sendMail start!')
        setSent(true)
      })
      .catch((e) => {
        const errorCode = e.code
        const errorMessage = e.message
        console.log(errorCode, errorMessage)
        switch (e.code) {
          case 'auth/network-request-failed':
            alert(
              'メール送信に失敗しました。時間をおき、通信環境がいい所で再度やり直してください。'
            )
            break
          case 'auth/too-many-requests':
            alert(
              'メール送信に失敗しました。時間をおき、通信環境がいい所で再度やり直してください。。'
            )
            break
          case 'auth/invalid-email':
            alert(
              '無効なメールアドレスです。マイページからメールアドレスの変更をしてください。'
            )
            break
          case 'auth/user-not-found':
            alert('メールアドレスが間違えています。')
            break
          case 'auth/user-disabled':
            alert('入力されたメールアドレスは無効になっています。')
            break
          default: //想定外
            alert(
              'メール送信に失敗しました。通信環境がいい所で再度やり直してください。'
            )
        }
      })
  }

  return (
    <Wrapper>
      {sent && <SentPaper />}
      {!sent && (
        <>
          <Typography component="p" align="left">
            ドレスの登録にはメールアドレスの認証を完了させる必要があります
          </Typography>
          <Typography component="p" align="left">
            ご登録いただいたメールアドレスあてに確認メールをお届けします。メール内のURLをクリックすると認証完了です。
          </Typography>
          <TextField
            disabled
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <ResetButton onClick={sendMail} />
        </>
      )}
    </Wrapper>
  )
}

export default withRouter(MailVertificationScreen)
