import { makeStyles } from '@material-ui/core'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
  },
}))

export function HomeMoreListButton() {
  const classes = useStyles()
  return (
    <div className={classes.root} style={{ paddingBottom: 16 }}>
      <Link component={RouterLink} to={'/list'} variant={'h5'}>
        もっと見る <ArrowForwardIosIcon style={{ display: 'flex' }} />
      </Link>
    </div>
  )
}
