import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import userService, { UserDoc } from '../../service/user-service'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { User, getAuth } from 'firebase/auth'

interface P {
  user: User | null
}
interface S {
  user?: UserDoc

  uploading: boolean
  registered: boolean

  prevEmail: string
  currentEmail?: string
  password: string
}

const styles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}))

class EditEmailPWScreen extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      user: undefined,
      uploading: true,
      registered: false,

      prevEmail: '',
      currentEmail: '',
      password: '',
    }
  }

  componentDidMount() {
    if (!this.props.user!.uid) {
      return
    }
    const userUid = this.props.user!.uid
    const emailAuth = getAuth().currentUser?.email
    userService.getV2(userUid).then((user?: UserDoc) => {
      if (!user) {
        this.setState({
          uploading: false,
          registered: false,
        })
        return
      }
      this.setState({
        uploading: false,
        registered: false,
        user,
        // @ts-ignore ここは絶対入るはずなので
        prevEmail: emailAuth,
        // @ts-ignore ここは絶対入るはずなので
        currentEmail: emailAuth,
      })
    })
  }

  changeEmail = (e: any) => {
    const value: string = e.target.value
    this.setState({ currentEmail: value })
  }

  changePassword = (e: any) => {
    const value: string = e.target.value
    this.setState({ password: value })
  }

  FormWrapper = (props: { children: React.ReactNode }) => {
    const classes = styles()
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>{props.children}</Paper>
      </main>
    )
  }

  updateUser = async (event: any) => {
    const { user, prevEmail, currentEmail, password } = this.state
    if (!user) {
      alert(
        '設定に失敗しました。「お問い合わせ」から登録したメールアドレスを記入して問い合わせて下さい。'
      )
      return
    }
    if (!currentEmail || !password) {
      alert('「*」のついた必須項目を入力してください')
      return
    }
    event.preventDefault()
    await userService
      .updateEmail(user, prevEmail, currentEmail, password)
      .then((result) => {
        if (result) {
          this.setState({ registered: true })
        } else {
          alert(
            '設定に失敗しました。時間を置いて再度お試し頂くか、それでも解決しない場合は「お問い合わせ」から問い合わせて下さい。'
          )
        }
      })
  }

  UnderButtonArea = () => {
    return (
      <div>
        {!this.state.registered && (
          <Button
            disabled={this.state.uploading || this.state.registered}
            variant="contained"
            onClick={this.updateUser}
            style={{ backgroundColor: '#DBD1E8' }}
          >
            更新
          </Button>
        )}
        {this.state.registered && (
          <Link to="/user/mypage" style={{ textDecoration: 'none' }}>
            <Button color="primary">マイページへ</Button>
          </Link>
        )}
      </div>
    )
  }

  render() {
    return (
      <this.FormWrapper>
        <Typography component="h1" variant="h4" align="center">
          メールアドレス設定
        </Typography>
        <React.Fragment>
          <form>
            {!this.state.registered && (
              <React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="email"
                      name="enmail"
                      label="メールアドレス"
                      fullWidth
                      autoComplete="email"
                      helperText="※非公開　問い合わせ連絡を受け取れるメールアドレスを登録してください。"
                      value={this.state.currentEmail}
                      onChange={this.changeEmail}
                      disabled={this.state.registered}
                    />
                    <Typography variant="body1" align="left">
                      メールアドレスを変更すると確認メールが送信されます。メール内のURLをクリックすると変更完了です。
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="password"
                      name="password"
                      label="現在のパスワード"
                      fullWidth
                      type="password"
                      autoComplete="current-password"
                      helperText="※ログイン時に入力したパスワードを入力して下さい"
                      value={this.state.password}
                      onChange={this.changePassword}
                      disabled={this.state.registered}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            {this.state.registered && (
              <Typography variant="body1" align="center">
                確認メールが送信されました。メール内のURLをクリックすると変更完了です。
              </Typography>
            )}
            <this.UnderButtonArea />
            <div>{this.state.uploading && <CircularProgress />}</div>
          </form>
        </React.Fragment>
      </this.FormWrapper>
    )
  }
}

export default EditEmailPWScreen
