import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@mui/material/Skeleton'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '100%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}))

const DressCardSkelton = () => {
  const classes = useStyles()
  return (
    <Grid item key={''} xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <Skeleton variant="rectangular" sx={{ paddingTop: '100%' }} />
        <CardContent className={classes.cardContent}>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </CardContent>
        <CardActions>
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: 80 }} />
        </CardActions>
      </Card>
    </Grid>
  )
}

export default DressCardSkelton
