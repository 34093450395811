import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    height: 400,
  },
}))

const DressImageCard = (props: { imgUrl: string }) => {
  const classes = useStyles()
  return (
    <Grid item key={props.imgUrl} xs={12}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.cardMedia}
          image={props.imgUrl}
          title="Image title"
        />
      </Card>
    </Grid>
  )
}

export default DressImageCard
