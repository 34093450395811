import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}))

const DressListNone = () => {
  const classes = useStyles()
  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Grid container spacing={4} justify="center">
        <Typography gutterBottom variant="h5" component="h2">
          {'該当のドレスは0件です'}
        </Typography>
      </Grid>
    </Container>
  )
}

export default DressListNone
