import { auth } from './service'
import {
  getAuth,
  onAuthStateChanged,
  signInWithRedirect,
  GoogleAuthProvider,
  signOut,
} from 'firebase/auth'

const authUtils = {
  login: () => {
    const provider = new GoogleAuthProvider()
    const auth = getAuth()
    signInWithRedirect(auth, provider)
  },

  logout: () => {
    signOut(auth)
  },

  checkLogin: async () => {
    return await onAuthStateChanged(auth, (user) => {
      return user
    })
  },
}

export default authUtils
