export const DressCategories = [
  { value: 'Standard', label: 'スタンダード' },
  { value: 'Latin', label: 'ラテン' },
]

export const DressSellable = [
  { value: 'enable', label: '販売可' },
  { value: 'disable', label: '販売不可' },
  { value: 'undecided', label: '未定/応相談' },
]

export const DressColor = [
  '赤',
  'ピンク',
  '黄・オレンジ',
  '紫',
  '青',
  '水色',
  '緑',
  '白',
  '黒',
  'ゴールド',
  'カラフル',
  '豹柄',
  'その他',
]

export const DressLength = [140, 145, 150, 155, 160, 165, 170, 175, 180]
