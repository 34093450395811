import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
interface P {}
interface S {}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 2),
    margin: theme.spacing(2, 2, 2),
  },
  textLeft: {
    textAlign: 'left',
  },
  header: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  headerMiddle: {
    margin: theme.spacing(1.5, 0, 0.5, 0),
  },
}))

const AboutInfo = () => {
  const classes = useStyles()
  return (
    <div>
      <h2>はじめに</h2>
      <div className={classes.textLeft}>
        <Paper className={classes.root} elevation={3}>
          <Typography variant="h4" component="h5" className={classes.header}>
            ドレスレンタル掲示板とは
          </Typography>
          <Typography component="p">
            このサイトは、学連・アマチュアの競技ダンサーがドレスのレンタルを円滑に行えるよう学連OGが作った掲示板です
          </Typography>
        </Paper>
        <Paper className={classes.root} elevation={3}>
          <Typography variant="h4" component="h5" className={classes.header}>
            ドレスを載せたい
          </Typography>
          <Typography variant="body1">
            ドレスをレンタルに出したい、売りに出したいという方は「ドレス登録」より登録が可能です。
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            className={classes.headerMiddle}
          >
            ドレスの写真について
          </Typography>
          <Typography variant="body1" color="secondary">
            ドレスの写真は、必ず自身で撮影したものを使用してください。
          </Typography>
          <Typography variant="body1" color="secondary">
            レンタルするドレスの写真のみ使用可能です。
          </Typography>
          <Typography variant="body1">
            レンタルするドレス以外の写真は混乱を招く可能性があるため、当サイトでの掲載を禁止しています。
          </Typography>
          <Typography variant="body1">
            レンタルするドレスと関係のない画像等を掲載した場合は、ドレス登録者の故意または過失に関わらず、掲載の停止や削除、ドレス登録者の利用制限を行います。
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            className={classes.headerMiddle}
          >
            ドレスの説明文
          </Typography>
          <Typography variant="body1">
            ドレスのタイトルや説明文などを入力することが出来ます。ドレス名に加え、メーカーや色など、検索されそうな言葉を入力しましょう。
          </Typography>
          <Typography
            variant="h5"
            component="h5"
            className={classes.headerMiddle}
          >
            お問い合わせ
          </Typography>
          <Typography variant="body1">
            掲載に関する質問やその他ご不明点の確認は、「お問い合わせ」からメールをお願い致します。
          </Typography>
        </Paper>
        <Paper className={classes.root} elevation={3}>
          <Typography variant="h4" component="h5" className={classes.header}>
            ドレスを借りたい
          </Typography>
          <Typography variant="body1">
            個別のドレスページにある「問い合わせる」ボタンから、ドレスの持主へ直接お問い合わせをすることが可能です。
          </Typography>
          <Typography variant="body1">
            問い合わせを行うと、持ち主へメールが送信されます。
          </Typography>
          <Typography variant="body1">
            以降のやり取りは、メールにて行って下さい。
          </Typography>
        </Paper>
        <Paper className={classes.root} elevation={3}>
          <Typography variant="h4" component="h5" className={classes.header}>
            レンタルガイド
          </Typography>
          <Typography variant="body1">
            ドレスをレンタルする際は細心の注意を払い、マナーを守ってお借りしましょう。マナーについてはレンタルマナーをご確認ください
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            className={classes.headerMiddle}
          >
            レンタルの依頼をするとき
          </Typography>
          <Typography variant="body1">
            レンタルの依頼をする場合は大会の２、３週間前、最低でも１週間前には依頼をするようにしましょう。
          </Typography>
          <Typography variant="body1">
            直前すぎると持ち主の方に迷惑がかかることはもちろんのこと、既に予約が入っている可能性があるだけでなく、万が一試着をして合わなかった場合、大会当日になってもドレスがない…ということになってしまいます。
          </Typography>
          <Typography variant="body1">　</Typography>
          <Typography variant="body1">
            ドレスの持ち主の方にメールでレンタルをお願いする際は、たとえ顔見知りであっても必ず
          </Typography>
          <Typography variant="body1">
            名前（あだ名ではなく本名）、大学名、学年、いつ、何の大会で着用したいのか
          </Typography>
          <Typography variant="body1">
            を、明記しましょう。最低限の情報がないと、持ち主の方も返答に困ってしまいます。
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            className={classes.headerMiddle}
          >
            受け渡し
          </Typography>
          <Typography variant="body1">
            お借りできることになった場合、受け渡しの方法を確認します。受け渡しには主に
          </Typography>
          <Typography variant="body1">
            ①本人同士、②連盟を介して、③宅配便で送る
          </Typography>
          <Typography variant="body1">
            の３種類があります。①か②が一般的ですが、時期と、持ち主の都合の良い方法を聞いて受け渡しましょう。
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            className={classes.headerMiddle}
          >
            レンタル料について
          </Typography>
          <Typography variant="body1">
            レンタル料はドレスや持ち主によって異なります。
          </Typography>
          <Typography variant="body1">
            レンタル料をお聞きするのは失礼なことではないので、もしお聞きして厳しいようなら早めにお断りしましょう。
          </Typography>
          <Typography variant="body1">
            レンタル料を払うのはドレスの返却のときが一般的です。お金は最少枚数、可能なら新札で、必ず封筒に入れて用意しましょう。
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            className={classes.headerMiddle}
          >
            ドレス保管方法
          </Typography>
          <Typography variant="body1">
            ドレスの保管方法についてはハンガーで吊るすか、広い場所に上にものを置かずに寝かせておくなどして、しわにならないようにするのが一般的ですが、ドレスによって多少違うこともあるので、持ち主に確認をとりましょう。
          </Typography>
          <Typography variant="body1">
            また、もし試着をしてサイズが合わないようであったら、無理をせず返却しましょう。
            無理に着ると破損の原因になります。
          </Typography>
          <Typography variant="body1">
            もし何らかのトラブルがあった際は、発覚した時点で速やかに持ち主に連絡を行いましょう。
          </Typography>
        </Paper>

        <Paper className={classes.root} elevation={3}>
          <Typography variant="h4" component="h5" className={classes.header}>
            管理者
          </Typography>
          <Typography variant="body1">
            <a
              href="https://twitter.com/dressBoardJp"
              target="_blank"
              rel="noreferrer noopener"
            >
              ドレスレンタル掲示板 公式アカウント
            </a>
          </Typography>
        </Paper>
      </div>
    </div>
  )
}

class About extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    return <AboutInfo />
  }
}

export default About
