import userService, { UserDoc } from './user-service'
import { isDev } from './firebase'
import {
  serverTimestamp,
  FieldValue,
  query,
  collection,
  where,
  getDoc,
  orderBy,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  limit,
} from 'firebase/firestore'
import { User } from 'firebase/auth'
import { db, firebaseFunctions } from './service'
import { httpsCallable } from 'firebase/functions'

export interface DressDoc {
  dressId?: string
  category: string
  title: string
  description: string
  lengthMax: number
  lengthMin: number
  color: string[]
  maker?: string
  accesory?: string
  sellable?: string
  imgUrls?: string[] | string
  imgUrl1?: string
  imgUrl2?: string
  imgUrl3?: string
  imgUrl4?: string
  createdAt?: FieldValue
  updatedAt?: FieldValue

  ownerUniv?: string
  ownerUid?: string
  ownerGrade?: string
  isDeleted?: boolean
  favoCount?: number

  isFavorited?: boolean // DBには持たない frontendでuserのfavoを参照して追加する
}

const dressesRef = collection(db, 'dresses')

const dressService = {
  get: async (dressId: string) => {
    const docRef = doc(db, 'dresses', dressId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      return docSnap.data()
    } else {
      console.log('No such document! dressId=', dressId)
      return undefined
    }
  },

  getAll: async () => {
    const q = query(dressesRef, orderBy('createdAt', 'desc'))
    const querySnapshot = await getDocs(q)
    const dresses: any[] = []
    querySnapshot.forEach((doc) => {
      const dressDoc = doc.data()
      dressDoc.dressId = doc.id
      dresses.push(dressDoc)
    })
    return dresses
  },

  getLatests: async () => {
    const q = query(dressesRef, orderBy('createdAt', 'desc'), limit(6))
    const querySnapshot = await getDocs(q)
    const dresses: any[] = []
    querySnapshot.forEach((doc) => {
      const dressDoc = doc.data()
      dressDoc.dressId = doc.id
      dresses.push(dressDoc)
    })
    return dresses
  },

  getByColor: async (color: string) => {
    const q = query(
      dressesRef,
      where('color', 'array-contains', color),
      orderBy('createdAt', 'desc')
    )
    const querySnapshot = await getDocs(q)
    const dresses: any[] = []
    querySnapshot.forEach((doc) => {
      const dressDoc = doc.data()
      dressDoc.dressId = doc.id
      dresses.push(dressDoc)
    })
    return dresses
  },

  getByML: async (ml: string) => {
    const q = query(
      dressesRef,
      where('category', '==', ml),
      orderBy('createdAt', 'desc')
    )
    const querySnapshot = await getDocs(q)
    const dresses: any[] = []
    querySnapshot.forEach((doc) => {
      const dressDoc = doc.data()
      dressDoc.dressId = doc.id
      dresses.push(dressDoc)
    })
    return dresses
  },

  getByMLAndColor: async (ml: string, color: string) => {
    const q = query(
      dressesRef,
      where('category', '==', ml),
      where('color', 'array-contains', color),
      orderBy('createdAt', 'desc')
    )
    const querySnapshot = await getDocs(q)
    const dresses: any[] = []
    querySnapshot.forEach((doc) => {
      const dressDoc = doc.data()
      dressDoc.dressId = doc.id
      dresses.push(dressDoc)
    })
    return dresses
  },

  regist: async (dress: DressDoc, user: User) => {
    const owner: UserDoc | undefined = await userService.getV2(user.uid)
    const docRef = await addDoc(dressesRef, {
      ...dress,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      ownerUid: user.uid,
      ownerUniv: owner ? owner.university : null,
      ownerGrade: owner ? owner.grade : null,
    })
    // console.log('Document written with ID: ', docRef.id)
    await kickTwitter({ ...dress, dressId: docRef.id })
    return docRef.id
  },

  update: async (dress: DressDoc, user: User) => {
    const owner: UserDoc | undefined = await userService.getV2(user.uid)
    // 分からんけど動確したら大丈夫そうだった。。 https://github.com/MichaelSolati/geofirestore-js/issues/188
    // @ts-ignore
    const dressRef = doc(db, 'dresses', dress.dressId)
    const res = await updateDoc(dressRef, {
      ...dress,
      updatedAt: serverTimestamp(),
      ownerUid: user.uid,
      ownerUniv: owner ? owner.university : null,
      ownerGrade: owner ? owner.grade : null,
    })
      .then(() => {
        return true
      })
      .catch((e: any) => {
        console.log(e)
        return null
      })
    return res
  },

  getOwnDress: async (uid: string) => {
    const q = query(dressesRef, where('ownerUid', '==', uid))
    const querySnapshot = await getDocs(q)
    const dresses: any[] = []
    querySnapshot.forEach((doc) => {
      const dressDoc = doc.data()
      dressDoc.dressId = doc.id
      dresses.push(dressDoc)
    })
    return dresses
  },

  delete: async (dressId: string) => {
    await deleteDoc(doc(db, 'dresses', dressId))
  },
}
export default dressService

const kickTwitter = async (dress: DressDoc) => {
  if (isDev) return true
  const sendMail = httpsCallable(firebaseFunctions, 'registerNewDress')
  const res = await sendMail({
    ...dress,
  }).catch(console.warn)
  // console.log('res: ', res)
  if (!res) {
    console.log('registerNewDress failed.res')
  }
  return res
}
