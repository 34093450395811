import React from 'react'
import { RouteComponentProps, Link, withRouter } from 'react-router-dom'
import dressService, { DressDoc } from '../../service/dress-service'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { DressCategories } from '../../component/DressEnum'
import { DressDetailImgs } from '../../component/DressDetailImgs'
import { User } from 'firebase/auth'
import IconButton from '@mui/material/IconButton'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import favoriteService from '../../service/favorites-service'
import Box from '@mui/material/Box'
import SharePopover from '../../component/SharePopover'
import userService from '../../service/user-service'
import { pink } from '@mui/material/colors'

interface P extends RouteComponentProps<{ dressId: string }> {
  user?: User | null
}
interface S {
  dress: DressDoc | undefined
  isLoaded: boolean
  isFavorited: boolean
}

const styles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 4, 6, 4),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  description: {
    whiteSpace: 'pre-line',
  },
}))

const NoDress = () => {
  const classes = styles()
  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          対象のドレスが見つかりません
        </Typography>
      </Container>
    </div>
  )
}

const Loading = () => {
  const classes = styles()
  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Loading
        </Typography>
      </Container>
    </div>
  )
}

const ContactButton = (props: { contactLink: string }) => {
  const classes = styles()
  return (
    <div className={classes.heroButtons}>
      <Grid container spacing={2} justify="center">
        <Grid item>
          <Link to={props.contactLink} style={{ textDecoration: 'none' }}>
            <Button variant="contained" style={{ backgroundColor: '#DBD1E8' }}>
              ドレスのお問い合わせ
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}

class DressDetailScreen extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      dress: undefined,
      isLoaded: false,
      isFavorited: false,
    }
  }

  componentDidMount() {
    const dressId = this.props.match.params.dressId
    if (!dressId) {
      this.setState({
        isLoaded: true,
      })
      return
    }
    dressService.get(dressId).then((dress: any) => {
      console.log('dress', dress)
      this.setState({
        dress,
        isLoaded: true,
      })
      if (this.props.user) {
        userService
          .getFavorite(this.props.user.uid, dressId)
          .then((favoritedDress) => {
            console.log('favoritedDress:', favoritedDress)
            if (favoritedDress) this.setState({ isFavorited: true })
          })
      }
    })
    console.log('userUid', this.props.user?.uid)
  }

  private getOwner = (dress: DressDoc) => {
    if (dress.ownerGrade === 'og') {
      return dress.ownerUniv ? dress.ownerUniv + '(OG)' : '学連OG'
    }
    if (dress.ownerGrade === 'other') {
      return 'アマチュア・学連以外'
    }
    if (!dress.ownerUniv) {
      return '学連' + dress.ownerGrade + '年'
    }

    return dress.ownerUniv + ' ' + dress.ownerGrade + '年'
  }

  private getColorStr = (color: string[]) => {
    if (!color) return ''
    let colorStr = color[0]
    if (color.length >= 2) {
      colorStr += ', ' + color[1]
    }
    return colorStr
  }

  private getSellable = (sellable?: string) => {
    if (!sellable) return null
    switch (sellable) {
      case 'disable':
        return null
      case 'enable':
        return '販売可'
      case 'undecided':
        return '未定/応相談'
    }
    return null
  }

  private favo = (dress: DressDoc) => {
    console.log('favorited!')
    if (!this.props.user) {
      console.log('not logined.')
      this.props.history.push({
        pathname: '/user/login',
      })
      return
    }
    if (this.state.isFavorited) {
      favoriteService.remove(this.props.user?.uid!, dress.dressId!).then(() => {
        this.setState({ isFavorited: false })
      })
    } else {
      favoriteService.add(this.props.user?.uid!, dress).then(() => {
        this.setState({ isFavorited: true })
      })
    }
  }

  private DressDescription = (props: { dress: DressDoc | undefined }) => {
    const classes = styles()
    const dress = props.dress!
    const contactLink = '/contact/' + this.props.match.params.dressId
    const shareUrl =
      'https://dressboard.work/_detail/' + this.props.match.params.dressId
    const lengthString = dress.lengthMin + 'cm ~ ' + dress.lengthMax + 'cm'
    const category = DressCategories.filter(
      (c) => c.value === dress.category
    )[0].label
    const owner = this.getOwner(dress)
    const color = this.getColorStr(dress.color)
    const sellable = this.getSellable(dress.sellable)

    return (
      <div className={classes.heroContent}>
        <Container maxWidth="sm">
          <Typography
            component="h2"
            variant="h5"
            align="left"
            color="textPrimary"
            gutterBottom
          >
            {dress.title}
          </Typography>
          <Box flexDirection="row" justifyContent="flex-start" display="flex">
            <IconButton
              aria-label="add to favorites"
              onClick={() => this.favo(dress)}
              sx={{ borderRadius: 0 }}
            >
              {this.state.isFavorited ? (
                <FavoriteIcon sx={{ color: pink[500] }} />
              ) : (
                <FavoriteBorderIcon />
              )}
              <Typography noWrap>お気に入り</Typography>
            </IconButton>
            {/* <SharePopover shareUrl={shareUrl} /> */}
          </Box>

          <Typography
            variant="body1"
            align="left"
            color="textPrimary"
            paragraph
            className={classes.description}
          >
            {dress.description}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            color="textSecondary"
            paragraph
            className={classes.description}
          >
            {category}ドレス
          </Typography>
          {dress.maker && (
            <Typography
              variant="body2"
              align="left"
              color="textSecondary"
              paragraph
              className={classes.description}
            >
              メーカー： {dress.maker}
            </Typography>
          )}

          {dress.accesory && (
            <Typography
              variant="body2"
              align="left"
              color="textSecondary"
              paragraph
              className={classes.description}
            >
              付属品： {dress.accesory}
            </Typography>
          )}

          <Typography
            variant="body2"
            align="left"
            color="textSecondary"
            paragraph
            className={classes.description}
          >
            持ち主： {owner}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            color="textSecondary"
            paragraph
            className={classes.description}
          >
            対応身長： {lengthString}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            color="textSecondary"
            paragraph
            className={classes.description}
          >
            ドレスカラー： {color}
          </Typography>
          {sellable && (
            <Typography
              variant="body2"
              align="left"
              color="textSecondary"
              paragraph
              className={classes.description}
            >
              ドレスの販売について： {sellable}
            </Typography>
          )}
          <ContactButton contactLink={contactLink} />
        </Container>
      </div>
    )
  }

  render() {
    const { dress, isLoaded } = this.state
    let imgURLs: string[] = []
    if (dress) {
      if (dress.imgUrl1) imgURLs.push(dress.imgUrl1)
      if (dress.imgUrl2) imgURLs.push(dress.imgUrl2)
      if (dress.imgUrl3) imgURLs.push(dress.imgUrl3)
      if (dress.imgUrl4) imgURLs.push(dress.imgUrl4)
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          {dress && (
            <>
              <DressDetailImgs imgUrls={imgURLs} />
              <this.DressDescription dress={dress} />
            </>
          )}
          {!isLoaded && <Loading />}
          {!dress && isLoaded && <NoDress />}
        </main>
      </React.Fragment>
    )
  }
}

export default withRouter(DressDetailScreen)
