import React from 'react'
import './App.css'
import RegisterDressScreen from './user/RegisterDressScreen'
import YFooter from '../component/YFooter'
import YHeader from '../component/YHeader'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import DressDetailScreen from './public/DressDetailScreen'
import RegisterUserScreen from './user/RegisterUserScreen'
import LoginScreen from './public/LoginScreen'
import { auth } from '../service/service'
import ContactDressScreen from './public/ContactDressScreen'
import About from './static/About'
import SignUpScreen from './public/SignUpScreen'
import Terms from './static/Terms'
import LoginScreenRequire from './public/LoginScreenRequire'
import MyPageScreen from './user/MyPageScreen'
import EditDressScreen from './user/EditDressScreen'
import Inquiry from './static/Inquiry'
import EditUserScreen from './user/EditUserScreen'
import EditEmailPWScreen from './user/EditEmailPWScreen'
import { onAuthStateChanged, User } from 'firebase/auth'
import DressListPagenationScreen from './public/DressListPagenationScreen'
import HomeScreen from './public/HomeScreen'
import ResetPWScreen from './public/ResetPWScreen'
import FavoriteListPagenationScreen from './user/FavoriteListPagenationScreen'
import MailVertificationScreen from './user/MailVertificationScreen'

interface P {}
interface S {
  user: User | null
  authenticated: boolean
}

class App extends React.Component<P, S> {
  constructor(props: any) {
    super(props)
    this.state = { authenticated: false, user: null }
  }

  componentWillMount() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({
          authenticated: true,
          user: user,
        })
      } else {
        this.setState({
          authenticated: false,
          user: null,
        })
      }
    })
  }

  render() {
    const { authenticated, user } = this.state
    return (
      <React.Fragment>
        <div className="App">
          <header>
            <meta property="og:site_name" content="ドレスレンタル掲示板" />
            <title>ドレスレンタル掲示板</title>
          </header>
          <body>
            <BrowserRouter>
              <YHeader user={user} />
              <article>
                <Route
                  exact
                  path="/"
                  render={() => <HomeScreen user={user} />}
                />
                <Route
                  exact
                  path="/list"
                  component={DressListPagenationScreen}
                />
                <Route
                  path="/_detail/:dressId"
                  exact={true}
                  render={({ match }) => (
                    <Redirect to={`/detail/${match.params.dressId}`} />
                  )}
                />
                <Route
                  exact
                  path="/detail/:dressId"
                  // component={DressDetailScreen}
                  render={() => <DressDetailScreen user={user} />}
                />
                <Route
                  path="/contact/:dressId"
                  component={ContactDressScreen}
                />
                <Route
                  exact
                  path="/user/mypage"
                  render={() =>
                    authenticated ? (
                      <MyPageScreen user={user} />
                    ) : (
                      <Redirect to="/user/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/user/favorites"
                  render={() =>
                    authenticated ? (
                      <FavoriteListPagenationScreen user={user} />
                    ) : (
                      <Redirect to="/user/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/user/registDress"
                  render={() =>
                    authenticated ? (
                      <RegisterDressScreen user={user} />
                    ) : (
                      <Redirect to="/user/login/require" />
                    )
                  }
                />
                <Route
                  exact
                  path="/user/edit/dress/:dressId"
                  render={() =>
                    authenticated ? (
                      <EditDressScreen user={user} />
                    ) : (
                      <Redirect to="/user/login/require" />
                    )
                  }
                />
                <Route
                  exact
                  path="/user/edit/profile"
                  render={() =>
                    authenticated ? (
                      <EditUserScreen user={user} />
                    ) : (
                      <Redirect to="/user/login/require" />
                    )
                  }
                />
                <Route
                  exact
                  path="/user/edit/maailpw"
                  render={() =>
                    authenticated ? (
                      <EditEmailPWScreen user={user} />
                    ) : (
                      <Redirect to="/user/login/require" />
                    )
                  }
                />
                <Route
                  path="/user/regist"
                  render={() => <RegisterUserScreen user={user} />}
                />
                <Route
                  exact
                  path="/user/login"
                  render={() =>
                    authenticated === false ? (
                      <LoginScreen />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  exact
                  path="/user/login/require"
                  render={() =>
                    authenticated === false ? (
                      <LoginScreenRequire />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/user/mailverification"
                  render={() => <MailVertificationScreen />}
                />
                <Route exact path="/signup" render={() => <SignUpScreen />} />
                <Route
                  exact
                  path="/resetpw"
                  render={() =>
                    authenticated === false ? (
                      <ResetPWScreen />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route exact path="/about" component={About} />
                <Route exact path="/terms" component={Terms} />
                <Route exact path="/inquiry" component={Inquiry} />
              </article>
            </BrowserRouter>
            <YFooter />
          </body>
        </div>
      </React.Fragment>
    )
  }
}

export default App
