import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import Alert from '@mui/material/Alert'

interface P extends RouteComponentProps {}
interface S {
  email: string
  password: string
  errorMessage: string
  isEmailError: boolean
  isPasswordError: boolean
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Wrapper: React.StatelessComponent<React.Props<{}>> = ({ children }) => {
  const classes = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          ログイン
        </Typography>
        <form className={classes.form} noValidate>
          {children}
        </form>

        <SigninButton />
      </div>
    </Container>
  )
}

const LoginButton = (props: any) => {
  const classes = useStyles()
  return (
    <Button
      fullWidth
      variant="contained"
      className={classes.submit}
      onClick={props.login}
      style={{ backgroundColor: '#DBD1E8' }}
    >
      メールアドレスでログイン
    </Button>
  )
}

function SigninButton() {
  return (
    <Link to="/signup" style={{ marginBottom: 16 }}>
      <Button variant="outlined" color="primary">
        新規会員登録はこちら
      </Button>
    </Link>
  )
}

function ResetPWButton() {
  return (
    <Link to="/resetpw" style={{}}>
      パスワードを忘れた方はこちら
    </Link>
  )
}

class LoginScreen extends React.Component<P, S> {
  emailRef: React.RefObject<HTMLInputElement>
  constructor(props: P) {
    super(props)
    this.state = {
      email: '',
      password: '',
      errorMessage: '',
      isEmailError: false,
      isPasswordError: false,
    }
    this.emailRef = React.createRef()
  }

  changeEmail = (e: any) => {
    const value: string = e.target.value
    this.setState({ email: value, isEmailError: false })
  }

  changePassword = (e: any) => {
    const value: string = e.target.value
    this.setState({ password: value, isPasswordError: false })
  }

  login = (e: any) => {
    e.preventDefault()
    const { email, password } = this.state
    if (!email) {
      this.setState({ isEmailError: true }, () => {
        if (this.emailRef.current) {
          this.emailRef.current.focus() // メールアドレスのテキストフィールドにフォーカスを移動
        }
      })
      return
    }
    if (!password) {
      this.setState({ isPasswordError: true })
      return
    }
    const auth = getAuth()
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        this.props.history.push('/')
      })
      .catch((error) => {
        this.setState(
          {
            errorMessage: 'メールアドレスかパスワードに誤りがあります。',
          },
          () => {
            if (this.emailRef.current) {
              this.emailRef.current.focus() // メールアドレスのテキストフィールドにフォーカスを移動
            }
          }
        )
      })
  }

  render() {
    return (
      <Wrapper>
        <TextField
          inputRef={this.emailRef}
          variant="outlined"
          margin="normal"
          error={this.state.isEmailError}
          required
          fullWidth
          id="email"
          label="メールアドレス"
          name="email"
          autoComplete="email"
          autoFocus
          helperText={
            this.state.isEmailError ? 'メールアドレスを入力してください' : ''
          }
          value={this.state.email}
          onChange={this.changeEmail}
        />
        <TextField
          variant="outlined"
          margin="normal"
          error={this.state.isPasswordError}
          required
          fullWidth
          name="password"
          label="パスワード"
          type="password"
          id="password"
          helperText={
            this.state.isPasswordError ? 'パスワードを入力してください' : ''
          }
          autoComplete="current-password"
          value={this.state.password}
          onChange={this.changePassword}
        />
        {this.state.errorMessage && (
          <Alert severity="error">{this.state.errorMessage}</Alert>
        )}
        <LoginButton login={this.login} />
        <ResetPWButton />
      </Wrapper>
    )
  }
}

export default withRouter(LoginScreen)
