import React from 'react'
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import { DressColor } from './DressEnum'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: '100%',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  })
)

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

export default function SelectChip(props: {
  callback: any
  defaultValue?: any
}) {
  const classes = useStyles()
  const theme = useTheme()
  const [color, setColor] = React.useState<string[]>([])

  React.useEffect(() => {
    if (!props.defaultValue) return
    setColor(props.defaultValue)
  }, [props.defaultValue])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setColor(event.target.value as string[])
    props.callback(event.target.value)
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label">ドレスカラー</InputLabel>
        <Select
          id="demo-mutiple-chip"
          multiple
          value={color}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {DressColor.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, color, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>2色まで選択可能です</FormHelperText>
      </FormControl>
    </div>
  )
}
