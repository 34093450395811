import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import Container from '@mui/material/Container'
import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  cardGrid: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
}))

export const DressDetailImgs = (props: { imgUrls: string[] }) => {
  const classes = styles()
  if (props.imgUrls.length === 0) {
    return <React.Fragment></React.Fragment>
  }
  return (
    <Container className={classes.cardGrid} maxWidth="md">
      {props.imgUrls.map((imgUrl) => (
        <img src={imgUrl} height="100%" width="320" />
      ))}
    </Container>
    // <Container maxWidth="md">
    //   <Swiper
    //     spaceBetween={200}
    //     modules={[Pagination, Navigation]}
    //     navigation={true}
    //     pagination={true}
    //     onSlideChange={() => console.log('slide change')}
    //     onSwiper={(swiper) => console.log(swiper)}
    //     style={{ marginTop: 16 }}
    //   >
    //     {props.imgUrls.map((imgUrl) => (
    //       <SwiperSlide>
    //         <img src={imgUrl} alt={imgUrl} width="80%" />
    //       </SwiperSlide>
    //     ))}
    //   </Swiper>
    // </Container>
  )
}
