import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

interface P extends RouteComponentProps {}
interface S {
  email: string
  password: string
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  require: {
    margin: theme.spacing(0, 0, 2),
  },
}))

const Wrapper: React.StatelessComponent<React.Props<{}>> = ({ children }) => {
  const classes = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography variant="h6" className={classes.require}>
          ユーザー 登録 か ログインが必要です
        </Typography>
        <Typography component="h1" variant="h5">
          ログイン
        </Typography>
        <form className={classes.form} noValidate>
          {children}
        </form>
      </div>
    </Container>
  )
}

const LoginButton = (props: any) => {
  const classes = useStyles()
  return (
    <Button
      fullWidth
      variant="contained"
      className={classes.submit}
      onClick={props.login}
      style={{ backgroundColor: '#DBD1E8' }}
    >
      メールアドレスでログイン
    </Button>
  )
}

const SigninButton = (props: any) => {
  return <Link to="/signup">新規アカウント作成はこちら</Link>
}

class LoginScreenRequire extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      email: '',
      password: '',
    }
  }

  changeEmail = (e: any) => {
    const value: string = e.target.value
    this.setState({ email: value })
  }

  changePassword = (e: any) => {
    const value: string = e.target.value
    this.setState({ password: value })
  }

  login = (e: any) => {
    e.preventDefault()
    const { email, password } = this.state
    const auth = getAuth()
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        this.props.history.push('/')
      })
      .catch(alert)
  }

  render() {
    return (
      <Wrapper>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="メールアドレス"
          name="email"
          autoComplete="email"
          autoFocus
          value={this.state.email}
          onChange={this.changeEmail}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="パスワード"
          type="password"
          id="password"
          autoComplete="current-password"
          value={this.state.password}
          onChange={this.changePassword}
        />
        <LoginButton login={this.login} />
        <SigninButton />
      </Wrapper>
    )
  }
}

export default withRouter(LoginScreenRequire)
