import { auth, storage } from './service'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'

const generateRandomFileName = () => {
  const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const N = 16
  return Array.from(crypto.getRandomValues(new Uint32Array(N)))
    .map((n) => S[n % S.length])
    .join('')
}

const generateTodayString = () => {
  const date = new Date()
  return date
    .toISOString() //2022-02-05T21:00:00.000Z
    .replace(/[^0-9]/g, '') //20220205210000000
    .slice(0, -5) //202202052100
}
const storageService = {
  uploadImage: async (file: any, order: number) => {
    if (!file) return
    const userId = auth.currentUser?.uid
    if (!auth.currentUser?.uid) return
    const filePath =
      'dress/' +
      userId +
      '/' +
      generateTodayString() +
      '_' +
      order +
      '_' +
      generateRandomFileName()
    const storageRef = ref(storage, filePath)
    await uploadBytes(storageRef, file)
    const dlUrl = await getDownloadURL(storageRef)
    return dlUrl
  },
}
export default storageService
