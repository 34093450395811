import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { DressboardLogo } from './DressboardLogo'

export const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}))

export const TopInfo = () => {
  const classes = useStyles()
  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <DressboardLogo />
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          社交ダンス・競技ダンスの
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          ドレスレンタルやり取りを より手軽に。
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Link to="/user/registDress" style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#DBD1E8' }}
                >
                  ドレスを登録
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to="/list" style={{ textDecoration: 'none' }}>
                <Button variant="contained" style={{}}>
                  ドレスをさがす
                </Button>
              </Link>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  )
}
