import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import userService from '../../service/user-service'
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
} from 'firebase/auth'
import Alert from '@mui/material/Alert'

interface P extends RouteComponentProps {}
interface S {
  email: string
  password: string
  wasSent: boolean
  errorMessage: string
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const actionCodeSettings = {
  url: 'https://dressboard.work/user/regist',
  // url: 'http://localhost:3000/user/regist',
  handleCodeInApp: false,
}

const Wrapper: React.StatelessComponent<React.Props<{}>> = ({ children }) => {
  const classes = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          アカウント作成
        </Typography>
        <form className={classes.form} noValidate>
          {children}
        </form>
      </div>
    </Container>
  )
}
const SignUpButton = (props: any) => {
  const classes = useStyles()
  return (
    <Button
      fullWidth
      variant="contained"
      className={classes.submit}
      onClick={props.signin}
      style={{ backgroundColor: '#DBD1E8' }}
    >
      アカウント作成
    </Button>
  )
}

class SignUpScreen extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      email: '',
      password: '',
      wasSent: false,
      errorMessage: '',
    }
  }

  async componentDidMount() {
    const auth = await getAuth()
    if (auth.currentUser) {
      this.props.history.push('/')
    }
  }

  changeEmail = (e: any) => {
    const value: string = e.target.value
    this.setState({ email: value })
  }

  changePassword = (e: any) => {
    const value: string = e.target.value
    this.setState({ password: value })
  }

  signUp = (e: any) => {
    e.preventDefault()
    const { email, password } = this.state
    createUserWithEmailAndPassword(getAuth(), email, password)
      .then((user: any) => {
        userService.registNewUser()
        const auth = getAuth()
        sendEmailVerification(auth.currentUser!, actionCodeSettings)
          .then(() => {
            this.setState({ wasSent: true })
          })
          .catch((e) => {
            this.setState({ wasSent: true })
          })
      })
      .catch((error) => {
        this.setState(
          {
            errorMessage:
              'メールアドレスかパスワードに誤りがあります。ご確認いただき、変更してください。',
          },
          () => {}
        )
      })
  }

  render() {
    const { wasSent } = this.state
    return (
      <Wrapper>
        {!wasSent && (
          <>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="メールアドレス"
              name="email"
              autoComplete="email"
              autoFocus
              value={this.state.email}
              onChange={this.changeEmail}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              autoComplete="current-password"
              value={this.state.password}
              onChange={this.changePassword}
            />
          </>
        )}
        {wasSent && (
          <Typography variant="body1" align="center" style={{ margin: 16 }}>
            確認メールをお送りしました。届いた確認メールのリンクをクリックしてください。
          </Typography>
        )}
        {this.state.errorMessage && (
          <Alert severity="error">{this.state.errorMessage}</Alert>
        )}
        {!wasSent && <SignUpButton signin={this.signUp} />}
      </Wrapper>
    )
  }
}

export default withRouter(SignUpScreen)
