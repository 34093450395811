import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import userService from '../../service/user-service'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { User } from 'firebase/auth'

interface P {
  user: User | null
}
interface S {
  user: User | null

  uploading: boolean
  registered: boolean

  displayName: string
  university: string
  grade: string
  prefecture: string
  email: string
}

const styles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}))

const Grades = [
  { value: '1', label: '1年' },
  { value: '2', label: '2年' },
  { value: '3', label: '3年' },
  { value: '4', label: '4年' },
  { value: 'og', label: 'OG' },
  { value: 'other', label: 'アマチュア・学連以外' },
]

class RegisterUserScreen extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      user: null,
      uploading: false,
      registered: false,

      displayName: '',
      university: '',
      grade: '1',
      prefecture: '', //都道府県
      email: '',
    }
  }

  changeDisplayName = (e: any) => {
    const value: string = e.target.value
    this.setState({ displayName: value })
  }

  changeUniv = (e: any) => {
    const value: string = e.target.value
    this.setState({ university: value })
  }

  changeGrade = (e: any) => {
    const value: string = e.target.value
    this.setState({ grade: value })
  }

  changePrefecture = (e: any) => {
    const value: string = e.target.value
    this.setState({ prefecture: value })
  }

  FormWrapper = (props: { children: React.ReactNode }) => {
    const classes = styles()
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>{props.children}</Paper>
      </main>
    )
  }

  registUser = async (event: any) => {
    const { displayName, university, grade, prefecture } = this.state
    // console.log({ grade })
    if (!university && grade !== 'other') {
      alert('「*」のついた必須項目を入力してください')
      return
    }
    if (!displayName || !grade || !prefecture) {
      alert('「*」のついた必須項目を入力してください')
      return
    }
    event.preventDefault()
    // この動線では、メアド,id,userUidだけ詰まったdataが既に存在するので
    let user = await userService.getV2(this.props.user!.uid)
    user = {
      ...user,
      displayName,
      university,
      grade,
      prefecture,
    }
    await userService.update(user).then((res) => {
      if (res) {
        this.setState({ registered: true })
      } else {
        alert('登録に失敗しました。時間を置いて、再度試して下さい。')
      }
    })
  }

  UnderButtonArea = () => {
    return (
      <div>
        {!this.state.registered && (
          <Button
            disabled={this.state.uploading || this.state.registered}
            variant="contained"
            color="primary"
            onClick={this.registUser}
          >
            登録
          </Button>
        )}
        {this.state.registered && (
          <Link to="/user/registDress" style={{ textDecoration: 'none' }}>
            <Button color="primary">ドレスの登録へ</Button>
          </Link>
        )}
      </div>
    )
  }

  render() {
    return (
      <this.FormWrapper>
        <Typography component="h1" variant="h4" align="center">
          ユーザー登録
        </Typography>
        <React.Fragment>
          <form>
            {!this.state.registered && (
              <React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="displayName"
                      name="displayName"
                      label="名前"
                      helperText="問い合わせ者に対して公開する名前です。"
                      value={this.state.displayName}
                      onChange={this.changeDisplayName}
                      fullWidth
                      autoComplete="lname"
                      disabled={this.state.registered}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="university"
                      name="university"
                      required
                      label="大学"
                      fullWidth
                      onChange={this.changeUniv}
                      disabled={this.state.registered}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="select-ml"
                      select
                      label="学年(部歴)"
                      fullWidth
                      value={this.state.grade}
                      onChange={this.changeGrade}
                      margin="normal"
                      disabled={this.state.registered}
                    >
                      {Grades.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="state"
                      name="state"
                      label="都道府県"
                      fullWidth
                      onChange={this.changePrefecture}
                      helperText="受け渡しの際に参考になるため、ドレス情報に付随して公開されます"
                      disabled={this.state.registered}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            {this.state.registered && (
              <Typography variant="body1" align="center">
                ユーザー登録が完了しました。ドレスの登録が可能です！
              </Typography>
            )}
            <this.UnderButtonArea />
            <div>{this.state.uploading && <CircularProgress />}</div>
          </form>
        </React.Fragment>
      </this.FormWrapper>
    )
  }
}

export default RegisterUserScreen
