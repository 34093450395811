import { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { auth } from '../../service/service'
import { sendPasswordResetEmail } from 'firebase/auth'
import { styled } from '@mui/material/styles'

const Form = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}))
const Paper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

function Wrapper(props: any) {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper>
        <Typography component="h1" variant="h5">
          パスワードの再設定
        </Typography>
        <Form noValidate>{props.children}</Form>
      </Paper>
    </Container>
  )
}
const SubmitButton = styled(Button)`
  backgroundColor: "#DBD1E8",
  color: "black",
  margin: theme.spacing(3, 0, 2),
`

function ResetButton(props: any) {
  return (
    <SubmitButton
      fullWidth
      variant="contained"
      onClick={props.onClick}
      style={{ backgroundColor: '#DBD1E8', color: 'black' }}
    >
      送信
    </SubmitButton>
  )
}

function SentPaper() {
  return (
    <>
      <Typography variant="body1" align="center">
        パスワード再設定のメールを送信しました
      </Typography>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Button color="primary">トップへ</Button>
      </Link>
    </>
  )
}

function ResetPWScreen() {
  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)

  const resetPW = (e: any) => {
    e.preventDefault()
    console.log('resetpassword start')
    sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log('reset password success!')
        setSent(true)
      })
      .catch((e) => {
        const errorCode = e.code
        const errorMessage = e.message
        console.log(errorCode, errorMessage)
        switch (e.code) {
          case 'auth/network-request-failed':
            alert(
              '通信がエラーになったのか、またはタイムアウトになりました。通信環境がいい所で再度やり直してください。'
            )
            break
          case 'auth/invalid-email':
            alert('メールアドレスが間違えています。')
            break
          case 'auth/user-not-found':
            alert('メールアドレスが間違えています。')
            break
          case 'auth/user-disabled':
            alert('入力されたメールアドレスは無効になっています。')
            break
          default: //想定外
            alert(
              '処理に失敗しました。通信環境がいい所で再度やり直してください。'
            )
        }
      })
  }

  return (
    <Wrapper>
      {sent && <SentPaper />}
      {!sent && (
        <>
          <Typography component="p">
            ご登録いただいたメールアドレスあてにご案内をお届けします
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <ResetButton onClick={resetPW} />
        </>
      )}
    </Wrapper>
  )
}

export default withRouter(ResetPWScreen)
