import React, { useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import inquiryService from '../../service/inquiry-service'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 2),
    margin: theme.spacing(2, 2, 2),
  },
  textLeft: {
    textAlign: 'left',
  },
  header: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  headerMiddle: {
    margin: theme.spacing(1.5, 0, 0.5, 0),
  },
}))

const Inquiry = () => {
  const classes = useStyles()
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')

  const handleSubmit = (event: any) => {
    event.preventDefault()
    // console.log(message)
    if (message.length === 0) {
      alert(
        'お問い合わせ内容が書かれていません！内容を確認して、再度送信してください。'
      )
      return
    }
    const inquiry = { message, email }
    inquiryService.submit(inquiry).then((res) => {
      if (res) {
        alert('送信しました。お問い合わせありがとうございます！')
        setMessage('')
        setEmail('')
      } else {
        alert('登録に失敗しました。時間を置いて、再度試して下さい。')
      }
    })
  }

  return (
    <div>
      <div className={classes.textLeft}>
        <form onSubmit={handleSubmit}>
          <Paper className={classes.root} elevation={3}>
            <Typography variant="h4" component="h5" className={classes.header}>
              お問い合わせ
            </Typography>
            <Typography variant="body1">
              掲載に関する質問やその他ご不明点の確認は、こちらからお問い合わせください。
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  name="email"
                  label="連絡先メールアドレス（返信をご希望の場合）"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                  fullWidth
                  rows="1"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description"
                  name="description"
                  label="お問い合わせ内容"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value)
                  }}
                  multiline
                  fullWidth
                  rows="4"
                />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Button
                variant="contained"
                type="submit"
                style={{ marginTop: 20, backgroundColor: '#DBD1E8' }}
              >
                送信
              </Button>
            </Grid>
          </Paper>
        </form>
      </div>
    </div>
  )
}

export default Inquiry
