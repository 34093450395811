import { makeStyles } from '@material-ui/core'
import Pagination from '@mui/material/Pagination'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      justifyContent: 'center',
      display: 'flex',
    },
  },
}))

export function DressListPagination(props: {
  pageCount: number
  defaultPage: number
  changePage: any
}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Pagination
        count={props.pageCount}
        defaultPage={props.defaultPage}
        onChange={(e, page) => props.changePage(page)}
      />
    </div>
  )
}
