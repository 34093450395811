import { firebaseFunctions } from './service'
import 'firebase/compat/firestore'
import { FieldValue } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

export interface InquiryDoc {
  id?: string
  message: string
  email: string
  createdAt?: FieldValue
}

const inquiryService = {
  submit: async (contact: InquiryDoc) => {
    return await post(contact)
  },
}
export default inquiryService

const post = async (inquiry: InquiryDoc) => {
  const postInquiry = httpsCallable(firebaseFunctions, 'postInquiry')
  const res = await postInquiry({
    ...inquiry,
  }).catch(console.warn)
  // console.log('res: ', res)
  if (!res) {
    console.log('postInquiry failed.res')
  }
  return res
}
