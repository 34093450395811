import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import SimpleExpansionPanel from './ExpansionPanel'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { DressCategories, DressColor, DressLength } from './DressEnum'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}))

const DressListFilter = (props: {
  selectedColors: string
  setColor: any
  selectedML: any
  setML: any
  selectedLength: any
  setLength: any
}) => {
  const classes = useStyles()
  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <SimpleExpansionPanel>
          <Grid container justify="center">
            <Grid item xs={12} sm={6}>
              <TextField
                id="select-ml"
                select
                label="スタンダード・ラテン"
                fullWidth
                value={props.selectedML}
                onChange={props.setML}
                margin="normal"
              >
                {DressCategories.map((obj) => (
                  <MenuItem key={obj.value} value={obj.value}>
                    {obj.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="select-color"
                select
                label="ドレスカラー"
                fullWidth
                value={props.selectedColors}
                onChange={props.setColor}
                margin="normal"
              >
                {DressColor.map((color) => (
                  <MenuItem key={color} value={color}>
                    {color}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="select-length"
                select
                label="身長"
                fullWidth
                value={props.selectedLength}
                onChange={props.setLength}
                margin="normal"
              >
                {DressLength.map((length) => (
                  <MenuItem key={length} value={length}>
                    {length}cm
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </SimpleExpansionPanel>
      </Container>
    </div>
  )
}
export default DressListFilter
