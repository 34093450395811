import React from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import dressService, { DressDoc } from '../../service/dress-service'
import DeleteModal from '../../component/DeleteModal'
import { User } from 'firebase/auth'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import Grid from '@mui/material/Grid'

interface P {
  user: User | null
}
interface S {
  dresses: DressDoc[]
  favorites: DressDoc[]
}

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  header: {
    paddingTop: theme.spacing(2),
  },
}))

const TopInfo = () => {
  const classes = useStyles()
  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography
          component="h2"
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          マイページ
        </Typography>

        <div className={classes.heroButtons}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ marginTop: 20 }}
          >
            <Grid item>
              <Link to="/user/registDress" style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#DBD1E8',
                    width: 120,
                    height: 88,
                  }}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <AddBoxOutlinedIcon sx={{ marginTop: 1 }} />
                    </Grid>
                    <Grid item>ドレス登録</Grid>
                  </Grid>
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to="/user/favorites" style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#DBD1E8',
                    width: 120,
                    height: 88,
                  }}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <FavoriteBorderIcon sx={{ marginTop: 1 }} />
                    </Grid>
                    <Grid item>お気に入り一覧</Grid>
                  </Grid>
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to="/user/edit/profile" style={{ textDecoration: 'none' }}>
                <Button variant="contained" style={{ width: 120, height: 88 }}>
                  <Grid container direction="column">
                    <Grid item>
                      <PermIdentityIcon sx={{ marginTop: 1 }} />
                    </Grid>
                    <Grid item>プロフィールの設定</Grid>
                  </Grid>
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to="/user/edit/maailpw" style={{ textDecoration: 'none' }}>
                <Button variant="contained" style={{ width: 120, height: 88 }}>
                  <Grid container direction="column">
                    <Grid item>
                      <MailOutlineIcon sx={{ marginTop: 1 }} />
                    </Grid>
                    <Grid item>メールアドレスの設定</Grid>
                  </Grid>
                </Button>
              </Link>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  )
}

const DressCard = (props: { card: DressDoc; refresh: any }) => {
  const classes = useStyles()
  const detailUrl = '/detail/' + props.card.dressId
  const editUrl = '/user/edit/dress/' + props.card.dressId
  const imgUrl = props.card.imgUrl1
  const dressId: string = props.card.dressId ? props.card.dressId : ''
  return (
    <Grid item key={props.card.dressId} xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <Link to={detailUrl} style={{ textDecoration: 'none' }}>
          <CardMedia
            className={classes.cardMedia}
            image={imgUrl}
            title="Image title"
          />
        </Link>
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.card.title}
          </Typography>
          <Typography noWrap>{props.card.description}</Typography>
        </CardContent>
        <CardActions>
          <Link to={detailUrl} style={{ textDecoration: 'none' }}>
            <Button size="small" color="primary">
              詳細ページ
            </Button>
          </Link>
          <Link to={editUrl} style={{ textDecoration: 'none' }}>
            <Button size="small" color="primary">
              編集
            </Button>
          </Link>
          <DeleteModal dressId={dressId} refresh={props.refresh} />
        </CardActions>
      </Card>
    </Grid>
  )
}

const DressListContents = (props: { cards: DressDoc[]; refresh: any }) => {
  const classes = useStyles()
  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Grid container spacing={4}>
        {props.cards.map((card) => (
          <DressCard card={card} refresh={props.refresh} key={card.dressId} />
        ))}
      </Grid>
    </Container>
  )
}
const FavoritesListContents = (props: { cards: DressDoc[]; refresh: any }) => {
  const classes = useStyles()
  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Grid container spacing={4}>
        {props.cards.map((card) => (
          <DressCard card={card} refresh={props.refresh} key={card.dressId} />
        ))}
      </Grid>
    </Container>
  )
}

const ListHeader = (props: { text: string }) => {
  const classes = useStyles()
  return (
    <Typography component="h4" variant="h5" className={classes.header}>
      {props.text}
    </Typography>
  )
}

class MyPageScreen extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      dresses: [],
      favorites: [],
    }
  }

  componentDidMount() {
    dressService
      .getOwnDress(this.props.user!.uid)
      .then((dresses) => {
        this.setState({ dresses: dresses! })
      })
      .catch(console.warn)
  }

  private refresh = () => {
    dressService
      .getOwnDress(this.props.user!.uid)
      .then((dresses) => {
        this.setState({
          dresses: dresses!,
          favorites: dresses,
        })
      })
      .catch(console.warn)
  }

  render() {
    const { dresses, favorites } = this.state
    const refresh = () => this.refresh()
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <TopInfo />
          <ListHeader text={'投稿したドレス'} />
          <DressListContents cards={dresses} refresh={refresh} />
          {/* <ListHeader text={'お気に入り'} /> */}
          {/* <FavoritesListContents cards={favorites} refresh={refresh} /> */}
        </main>
      </React.Fragment>
    )
  }
}

export default MyPageScreen
