import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import { DressDoc } from '../service/dress-service'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import favoriteService from '../service/favorites-service'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '100%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}))

const favo = (userUid: string, dress: DressDoc) => {
  console.log('favorited!')
  favoriteService.add(userUid, dress)
}

const DressCard = (props: { card: DressDoc }) => {
  const classes = useStyles()
  const linkUrl = '/detail/' + props.card.dressId
  const imgUrl = props.card.imgUrl1
  const favoCount = props.card.favoCount === 0 ? '' : props.card.favoCount
  const favo = () => {
    console.log('favorited!')

    favoriteService.add('', props.card)
  }
  return (
    <Grid item key={props.card.dressId} xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <Link to={linkUrl} style={{ textDecoration: 'none' }}>
          <CardMedia
            className={classes.cardMedia}
            image={imgUrl}
            title="Image title"
          />
        </Link>
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.card.title}
          </Typography>
          <Typography noWrap>{props.card.description}</Typography>
        </CardContent>
        <CardActions>
          <Link to={linkUrl} style={{ textDecoration: 'none' }}>
            <Button size="small" color="primary" aria-label="see more">
              詳細を見る
            </Button>
          </Link>
          {/* <IconButton aria-label="add to favorites" onClick={favo}>
            {props.card.isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}

            <Typography noWrap>{favoCount}</Typography>
          </IconButton> */}
        </CardActions>
      </Card>
    </Grid>
  )
}

export default DressCard
