import { styled } from '@mui/system'
import { Link } from 'react-router-dom'
import dressboardLogoIconEn from '../assets/svg/dressboard_logo_icon_en_altesse.svg'
import dressboardLogoIconMini from '../assets/svg/dressboard_logo_icon_tapop_tomo.svg'

const Logo = styled('div')({
  textAlign: 'center',
})

const LogoMini = styled('div')({
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexGrow: 1,
})

export const DressboardLogo = () => {
  return (
    <Logo>
      <img src={dressboardLogoIconEn} alt="ドレスレンタル掲示板" />
    </Logo>
  )
}

export const DressboardLogoMini = () => {
  return (
    <LogoMini>
      <Link
        to="/"
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <img src={dressboardLogoIconMini} alt="Dressboard Logo" height={32} />
      </Link>
    </LogoMini>
  )
}
