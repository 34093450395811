import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import authUtils from '../service/firebase-auth-utils'
import { User } from 'firebase/auth'
import { DressboardLogoMini } from './DressboardLogo'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
)

const YHeader = (props: { user: User | null }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    authUtils.logout()
  }

  return (
    <AppBar
      position="relative"
      style={{ color: '#434344', backgroundColor: '#dbd6db' }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem component={Link} to="/" style={{}} onClick={handleClose}>
            トップページ
          </MenuItem>
          <MenuItem component={Link} to="/user/mypage" onClick={handleClose}>
            マイページ
          </MenuItem>
          <MenuItem
            component={Link}
            to="/user/registDress"
            onClick={handleClose}
          >
            ドレス登録
          </MenuItem>
          <MenuItem component={Link} to="/user/favorites" onClick={handleClose}>
            お気に入り
          </MenuItem>
          <MenuItem component={Link} to="/about" onClick={handleClose}>
            はじめに
          </MenuItem>
          <MenuItem component={Link} to="/terms" onClick={handleClose}>
            利用規約
          </MenuItem>
          <MenuItem component={Link} to="/inquiry" onClick={handleClose}>
            お問い合わせ
          </MenuItem>
        </Menu>
        <DressboardLogoMini />
        {!props.user && (
          <Button color="inherit">
            <Link
              to="/user/login"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              ログイン
            </Link>
          </Button>
        )}
        {props.user && (
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Button color="inherit" onClick={logout}>
              ログアウト
            </Button>
          </Link>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default YHeader
