import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import dressService, { DressDoc } from '../../service/dress-service'
import DressCard from '../../component/DressCard'
import DressListNone from '../../component/DressListNone'
import DressListFilter from '../../component/DressListFilter'
import DressCardSkelton from '../../component/DressCardSkelton'
import { DressListPagination } from '../../component/DressListPagination'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface P extends RouteComponentProps<{ page: string }> {}
interface S {
  selectedColors: string
  selectedML: string
  selectedLength: number
  dresses: DressDoc[]
  dressesDisplayed: DressDoc[]
  firstLoading: boolean
  pageCount: number
  page: number
}

const ITEMS_PER_PAGE = 12

const sliceDressesDisplayed = (page: number, dresses: DressDoc[]) => {
  return dresses.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
}

const calcPageCount = (dressesLength: number) => {
  return ((dressesLength / ITEMS_PER_PAGE) | 0) + 1
}
const getPage = (location: any) => {
  let page = location.state && location.state.page
  if (page === undefined) {
    page = 1
  }
  return page
}

const getML = (location: any) => {
  let ml = location.state && location.state.ml
  if (ml === undefined) {
    ml = ''
  }
  return ml
}

const getColor = (location: any) => {
  let color = location.state && location.state.color
  if (color === undefined) {
    color = ''
  }
  return color
}

const getLength = (location: any) => {
  let length = location.state && location.state.length
  if (length === undefined) {
    length = -1
  }
  return length
}

const returnTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}))

const FirstLoading = () => {
  const classes = useStyles()
  const skeltons = Array.from(new Array(ITEMS_PER_PAGE))
  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Grid container spacing={4}>
        {skeltons.map((item, index) => (
          <DressCardSkelton key={index} />
        ))}
      </Grid>
    </Container>
  )
}

const DressListContents = (props: { cards: DressDoc[] }) => {
  const classes = useStyles()
  if (!props.cards || props.cards.length === 0) {
    return <DressListNone />
  }
  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Grid container spacing={4}>
        {props.cards.map((card) => (
          <DressCard card={card} key={card.dressId} />
        ))}
      </Grid>
    </Container>
  )
}

class DressListPagenationScreen extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      selectedColors: getColor(props.location),
      selectedML: getML(props.location),
      selectedLength: getLength(props.location),
      dresses: [],
      dressesDisplayed: [],
      firstLoading: true,
      pageCount: 10,
      page: getPage(props.location),
    }
  }

  componentDidMount() {
    this.filter()
  }

  private setColor = (e: any) => {
    const value: string = e.target.value
    this.setState({ selectedColors: value, page: 1 }, () => this.filter())
  }

  private setML = (e: any) => {
    const value: string = e.target.value
    this.setState({ selectedML: value, page: 1 }, () => this.filter())
  }

  private setLength = (e: any) => {
    const value: number = e.target.value
    this.setState({ selectedLength: value, page: 1 }, () => this.filter())
  }

  private filter = async () => {
    const dresses = await this.getFilteredDresses()
    this.updateDisplayedAndTotalDresses(dresses)
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      state: {
        ml: this.state.selectedML,
        color: this.state.selectedColors,
        length: this.state.selectedLength,
        page: this.state.page,
      },
    })
  }

  private getFilteredDresses = async () => {
    const { selectedML, selectedColors, selectedLength } = this.state
    const isOnlyColor = selectedML.length === 0 && selectedColors.length > 0
    const isOnlyML = selectedML.length > 0 && selectedColors.length === 0
    const isMLAndColor = selectedML.length > 0 && selectedColors.length > 0
    let dresses: DressDoc[] = []
    if (isOnlyColor) {
      dresses = await dressService.getByColor(selectedColors)
    } else if (isOnlyML) {
      dresses = await dressService.getByML(selectedML)
    } else if (isMLAndColor) {
      dresses = await dressService.getByMLAndColor(selectedML, selectedColors)
    } else {
      dresses = await dressService.getAll()
    }
    if (selectedLength > 0) {
      dresses = dresses.filter(
        (dress) =>
          dress.lengthMax >= selectedLength && dress.lengthMin <= selectedLength
      )
    }
    dresses = dresses.filter((dress) => dress.isDeleted !== true)
    return dresses
  }

  private updateDisplayedAndTotalDresses = (dresses: DressDoc[]) => {
    const { page } = this.state
    this.setState({
      pageCount: calcPageCount(dresses.length),
      dresses: dresses,
      dressesDisplayed: sliceDressesDisplayed(page, dresses),
      firstLoading: false,
    })
  }

  private changePage = (page: number) => {
    const { dresses } = this.state
    this.setState({
      page,
      dressesDisplayed: sliceDressesDisplayed(page, dresses),
    })
    returnTop()
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      state: {
        page: page,
        ml: this.state.selectedML,
        color: this.state.selectedColors,
        length: this.state.selectedLength,
      },
    })
  }

  render() {
    const dressesDisplayed: DressDoc[] = this.state.dressesDisplayed
    const {
      selectedColors,
      selectedML,
      selectedLength,
      firstLoading,
      pageCount,
      page,
    } = this.state
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <DressListFilter
            selectedColors={selectedColors}
            setColor={this.setColor}
            selectedML={selectedML}
            setML={this.setML}
            selectedLength={selectedLength}
            setLength={this.setLength}
          />
          {firstLoading && <FirstLoading />}
          {!firstLoading && <DressListContents cards={dressesDisplayed} />}
          <DressListPagination
            pageCount={pageCount}
            defaultPage={page}
            changePage={this.changePage}
          />
        </main>
      </React.Fragment>
    )
  }
}

export default withRouter(DressListPagenationScreen)
