import { firebaseFunctions } from './service'
import { FieldValue } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

export interface ContactDoc {
  id?: string
  dressId: string
  displayName: string
  email: string
  lineId: string
  university: string
  grade: string
  prefecture: string //都道府県
  object: string
  preferredDate: string
  message: string
  createdAt?: FieldValue
  ownerUid: string
}

const contactService = {
  submit: async (contact: ContactDoc) => {
    await postContact(contact)
    await sendEmail(contact)
    return true
  },
}
export default contactService

const sendEmail = async (contact: ContactDoc) => {
  const sendMail = httpsCallable(firebaseFunctions, 'sendMail')
  const res = await sendMail({
    ...contact,
  }).catch(console.warn)
  // console.log('res: ', res)
  if (!res) {
    console.log('mail send failed.res')
  }
  return res
}
const postContact = async (contact: ContactDoc) => {
  const postContact = httpsCallable(firebaseFunctions, 'postContact')
  const res = await postContact({
    ...contact,
  }).catch(console.warn)
  // console.log('res: ', res)
  if (!res) {
    console.log('postContact failed.res')
  }
  return res
}
