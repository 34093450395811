import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  fileButton: {
    opacity: 0,
    appearance: 'none',
    position: 'absolute',
  },
}))

const FileAttachButton = (props: {
  text?: string
  onChange: (e: any) => void
}) => {
  const classes = useStyles()
  return (
    <Button component="label">
      {props.text ? props.text : '画像をアップロード'}
      <input
        type="file"
        className={classes.fileButton}
        onChange={props.onChange}
        multiple
      />
    </Button>
  )
}

export default FileAttachButton
