import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { User } from 'firebase/auth'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { RouteComponentProps, Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import contactService, { ContactDoc } from '../../service/contact-service'
import dressService from '../../service/dress-service'

interface P extends RouteComponentProps<{ dressId: string }> {}
interface S {
  user: User | null

  uploading: boolean
  registered: boolean

  displayName: string
  email: string
  lineId: string
  university: string
  grade: string
  prefecture: string
  object: string
  message: string
  preferredDate: string

  isValidMail: boolean
}

const styles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}))

const Grades = [
  { value: '1', label: '1年' },
  { value: '2', label: '2年' },
  { value: '3', label: '3年' },
  { value: '4', label: '4年' },
  { value: 'og', label: 'OG' },
]

const REGEX =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

class ContactDressScreen extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      user: null,
      uploading: false,
      registered: false,

      displayName: '',
      email: '',
      lineId: '',
      university: '',
      grade: '1',
      prefecture: '', //都道府県
      object: '',
      preferredDate: '',
      message: '',

      isValidMail: true,
    }
  }

  componentDidMount() {}

  changeDisplayName = (e: any) => {
    this.setState({ displayName: e.target.value })
  }

  changeEmail = (e: any) => {
    this.setState({ email: e.target.value })
    if (e.target.value === '') this.setState({ isValidMail: true })
  }

  changeLineId = (e: any) => {
    this.setState({ lineId: e.target.value })
  }

  changeUniv = (e: any) => {
    this.setState({ university: e.target.value })
  }

  changeGrade = (e: any) => {
    this.setState({ grade: e.target.value })
  }

  changePrefecture = (e: any) => {
    this.setState({ prefecture: e.target.value })
  }

  changeObject = (e: any) => {
    this.setState({ object: e.target.value })
  }

  changePreferredDate = (e: any) => {
    this.setState({ preferredDate: e.target.value })
  }

  changeMessage = (e: any) => {
    this.setState({ message: e.target.value })
  }

  FormWrapper = (props: { children: React.ReactNode }) => {
    const classes = styles()
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>{props.children}</Paper>
      </main>
    )
  }
  //合致するならtrueを返す
  private validateMailAddress = (email: string) => {
    return REGEX.test(email)
  }

  onSubmit = async (event: any) => {
    const {
      displayName,
      email,
      lineId,
      university,
      grade,
      prefecture,
      object,
      preferredDate,
      message,
    } = this.state

    if (
      !displayName ||
      !object ||
      !grade ||
      !prefecture ||
      !email ||
      !preferredDate
    ) {
      alert('必要な情報を入力してください')
      return
    }
    const isValidMailAddress = this.validateMailAddress(email)
    console.log('isValidMailAddress ' + isValidMailAddress)
    if (!isValidMailAddress) {
      alert('正しい形式でメールアドレスを入力して下さい')
      return
    }
    event.preventDefault()
    //@ts-ignore
    const dressId = this.props.match.params.dressId
    if (!dressId) {
      return
    }
    this.setState({ uploading: true })
    const dressDoc = await dressService.get(dressId)
    if (!dressDoc) {
      alert('送信に失敗しました。時間を置いて、再度試して下さい。')
      // console.log('dressDoc取得失敗')
      this.setState({ uploading: false })
      return
    }
    const contact: ContactDoc = {
      dressId,
      displayName,
      email,
      lineId,
      university,
      grade,
      prefecture,
      object,
      preferredDate,
      message,
      ownerUid: dressDoc.ownerUid,
    }
    // console.log('送信')
    await contactService
      .submit(contact)
      .then((res) => {
        if (res) {
          alert('問い合わせを送信しました')
          this.setState({ registered: true, uploading: false })
        } else {
          alert('送信に失敗しました。時間を置いて、再度試して下さい。')
          this.setState({ uploading: false })
        }
      })
      .catch(console.warn)
  }

  UnderButtonArea = () => {
    return (
      <div>
        {!this.state.registered && (
          <Button
            disabled={this.state.uploading || this.state.registered}
            variant="contained"
            onClick={this.onSubmit}
            style={{ marginTop: 20, backgroundColor: '#DBD1E8' }}
          >
            送信
          </Button>
        )}
        {this.state.registered && (
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Button color="primary">トップページへ</Button>
          </Link>
        )}
      </div>
    )
  }

  render() {
    const isValidMail = this.state.isValidMail
    return (
      <this.FormWrapper>
        <Typography component="h1" variant="h4" align="center">
          レンタルの問い合わせ
        </Typography>
        <React.Fragment>
          <form>
            {!this.state.registered && (
              <React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="displayName"
                      name="displayName"
                      label="名前"
                      helperText="(必須)トラブル防止のため、本名を記入してください"
                      value={this.state.displayName}
                      onChange={this.changeDisplayName}
                      fullWidth
                      autoComplete="lname"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="email"
                      name="enmail"
                      label="メールアドレス"
                      error={!isValidMail}
                      fullWidth
                      inputProps={{
                        onBlur: () => {
                          const isValid =
                            this.state.email === '' ||
                            this.validateMailAddress(this.state.email)
                          this.setState({ isValidMail: isValid })
                        },
                      }}
                      autoComplete="email"
                      helperText={
                        isValidMail
                          ? '(必須)ドレスの持ち主とやり取りを行うメールアドレスになります'
                          : '正しい形式でメールアドレスを入力して下さい'
                      }
                      onChange={this.changeEmail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="lineId"
                      name="lineId"
                      label="LINE ID"
                      fullWidth
                      helperText="(任意)LINEでの返信を希望する場合は、メールアドレスに加えてLINE IDも記入可能です。"
                      onChange={this.changeLineId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="university"
                      name="university"
                      label="大学"
                      helperText="現役学連の方はトラブル防止のため、必ず記入してください"
                      fullWidth
                      onChange={this.changeUniv}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="select-ml"
                      select
                      label="学年(部歴)"
                      fullWidth
                      value={this.state.grade}
                      onChange={this.changeGrade}
                      margin="normal"
                    >
                      {Grades.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="state"
                      name="state"
                      label="都道府県"
                      fullWidth
                      onChange={this.changePrefecture}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="object"
                      name="object"
                      label="利用目的"
                      fullWidth
                      onChange={this.changeObject}
                      helperText="競技会、デモやパーティなどの、目安となることを記入"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="startDate"
                      label="利用希望日"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.changePreferredDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="message"
                      name="message"
                      label="メッセージ"
                      value={this.state.message}
                      onChange={this.changeMessage}
                      fullWidth
                      multiline
                      autoComplete="lname"
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            {this.state.registered && (
              <Typography variant="body1" align="center">
                問い合わせが完了しました。持ち主からの返信をお待ち下さい。
              </Typography>
            )}
            <this.UnderButtonArea />
            <div>{this.state.uploading && <CircularProgress />}</div>
          </form>
        </React.Fragment>
      </this.FormWrapper>
    )
  }
}

export default ContactDressScreen
