import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles({
  root: {
    // width: 300
  },
})
const marks = [
  {
    value: 140,
    label: '140cm',
  },
  {
    value: 180,
    label: '180cm',
  },
]

function valuetext(value: number) {
  return `${value}cm`
}

export default function InputRange(props: {
  callback?: any
  defaultValue?: any
}) {
  const classes = useStyles()
  const [length, setLength] = React.useState<number[]>([155, 165])

  React.useEffect(() => {
    if (!props.defaultValue) return
    setLength(props.defaultValue)
  }, [props.defaultValue])

  const handleChange = (event: any, newValue: number | number[]) => {
    setLength(newValue as number[])
    props.callback(newValue)
  }

  return (
    <div className={classes.root}>
      <Typography id="range-slider" gutterBottom>
        対応身長
      </Typography>
      <Slider
        value={length}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        step={5}
        max={180}
        min={140}
        marks={marks}
      />
      <FormHelperText>
        身長が何cmから何cmまで対応しているかを入力可能です(フリックで操作)
      </FormHelperText>
    </div>
  )
}
