import { db } from './service'
import {
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  FieldValue,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'
import { DressDoc } from './dress-service'

export interface FavoriteDoc {
  id?: string //dressIdと一致
  postRef: DocumentReference
  createdAt?: FieldValue
  updatedAt?: FieldValue
}

const favoriteService = {
  add: async (userUid: string, dress: DressDoc) => {
    return await add(userUid, dress)
  },
  remove: async (userUid: string, dressId: string) => {
    return await remove(userUid, dressId)
  },
  get: async (userUid: string) => {
    return await get(userUid)
  },
}
export default favoriteService

const add = async (userUid: string, dress: DressDoc) => {
  if (!dress) return
  const dressId = dress?.dressId
  // @ts-ignore
  const nycRef = doc(db, 'users', userUid, 'favorites', dressId)
  // @ts-ignore
  const dressRef = doc(db, 'dresses', dressId)
  await setDoc(nycRef, {
    ...dress,
    dressRef,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  })
  // この後にfunctions側でincrementする
  return
}

const remove = async (userUid: string, dressId: string) => {
  const nycRef = doc(db, 'users', userUid, 'favorites', dressId)
  await deleteDoc(nycRef)
  // この後にfunctions側でdecrementする
  return
}

const get = async (userUid: string) => {
  const favoriteRef = collection(db, 'users', userUid, 'favorites')
  const q = query(favoriteRef, orderBy('createdAt', 'desc'))
  const querySnapshot = await getDocs(q)
  const favorites: any[] = []
  querySnapshot.forEach((doc) => {
    const favorite = doc.data()
    favorites.push(favorite)
  })
  return favorites
}
