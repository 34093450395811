import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import dressService, { DressDoc } from '../../service/dress-service'
import DressCard from '../../component/DressCard'
import DressListNone from '../../component/DressListNone'
import DressCardSkelton from '../../component/DressCardSkelton'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { TopInfo } from '../../component/TopInfo'
import { HomeMoreListButton } from '../../component/HomeMoreListButton'
import Typography from '@mui/material/Typography'
import { User } from 'firebase/auth'

interface P extends RouteComponentProps<{ page: string }> {
  user?: User | null
}
interface S {
  dresses: DressDoc[]
  firstLoading: boolean
}

const ITEMS_PER_PAGE = 6

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  header: {
    paddingTop: theme.spacing(2),
  },
}))
const DressListHeader = () => {
  const classes = useStyles()
  return (
    <Typography component="h4" variant="h5" className={classes.header}>
      新着ドレス
    </Typography>
  )
}

const FirstLoading = () => {
  const classes = useStyles()
  const skeltons = Array.from(new Array(ITEMS_PER_PAGE))
  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Grid container spacing={4}>
        {skeltons.map((item, index) => (
          <DressCardSkelton key={index} />
        ))}
      </Grid>
    </Container>
  )
}

const DressListContents = (props: { cards: DressDoc[] }) => {
  const classes = useStyles()
  if (!props.cards || props.cards.length === 0) {
    return <DressListNone />
  }
  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Grid container spacing={4}>
        {props.cards.map((card) => (
          <DressCard card={card} key={card.dressId} />
        ))}
      </Grid>
    </Container>
  )
}

class HomeScreen extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      dresses: [],
      firstLoading: true,
    }
  }

  componentDidMount() {
    dressService
      .getLatests()
      .then((dresses) => {
        dresses = dresses.filter((dress) => dress.isDeleted !== true)
        this.setState({ dresses: dresses!, firstLoading: false })
      })
      .catch(console.warn)
  }

  render() {
    const { dresses, firstLoading } = this.state
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <TopInfo />
          <DressListHeader />
          {firstLoading && <FirstLoading />}
          {!firstLoading && <DressListContents cards={dresses} />}
          <HomeMoreListButton />
        </main>
      </React.Fragment>
    )
  }
}

export default withRouter(HomeScreen)
