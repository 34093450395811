import { initializeApp } from 'firebase/app'
export const isDev = false

const firebaseConfigProduction = {
  apiKey: 'AIzaSyC6bUvuCZF51RYcoHdKgiE8-FKigNSXiXE',
  authDomain: 'dressboard-8f9de.firebaseapp.com',
  databaseURL: 'https://dressboard-8f9de.firebaseio.com',
  projectId: 'dressboard-8f9de',
  storageBucket: 'dressboard-8f9de.appspot.com',
  messagingSenderId: '108708639420',
  appId: '1:108708639420:web:a99afeb82dd199995f56b9',
  measurementId: 'G-SVBLB920N0',
}

const firebaseConfigDev = {
  apiKey: 'AIzaSyDYvvjeMNSu950fmzpPGkn7NiDyAvPzKZw',
  authDomain: 'dressboard-dev.firebaseapp.com',
  databaseURL: 'https://dressboard-dev.firebaseio.com',
  projectId: 'dressboard-dev',
  storageBucket: 'dressboard-dev.appspot.com',
  messagingSenderId: '396558864327',
  appId: '1:396558864327:web:481577013c88225133d3e2',
  measurementId: 'G-V3CNX4X01P',
}

export const firebaseApp = initializeApp(
  isDev ? firebaseConfigDev : firebaseConfigProduction
)
