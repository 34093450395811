import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
interface P {}
interface S {}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2, 2),
    margin: theme.spacing(2, 2, 2)
  },
  textLeft: {
    textAlign: 'left'
  },
  header: {
    margin: theme.spacing(4, 0, 2, 0)
  },
  headerMiddle: {
    margin: theme.spacing(1.5, 0, 0.5, 0)
  }
}))

const TermInfo = () => {
  const classes = useStyles()
  return (
    <div>
      <h2>利用規約</h2>
      <div className={classes.textLeft}>
        <Paper className={classes.root} elevation={3}>
          <Typography component="p">
            本サイト(dressboard.work)を利用するにはこの利用規約について同意する必要があります。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第1条（適用）
          </Typography>
          <Typography component="p">
            本規約は，ドレス掲示板で提供するサービス（以下，本サービス）の利用条件を定めるものです。登録ユーザーの皆さま（以下，ユーザー）には，本規約に従って，本サービスをご利用いただきます。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第2条（利用登録）
          </Typography>
          <Typography variant="body1">
            本サービスにおいては，登録希望者が本規約に同意の上，定める方法によって利用登録を申請し，運営者がこの承認を登録希望者に通知することによって，利用登録が完了するものとします。
            運営者は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
          </Typography>
          <Typography variant="body1">
            (1)利用登録の申請に際して虚偽の事項を届け出た場合
            (2)本規約に違反したことがある者からの申請である場合
            (3)その他，運営者が利用登録を相当でないと判断した場合
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第3条（ユーザーの責任）
          </Typography>
          <Typography variant="body1">
            ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
            ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。運営者は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。
            ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，運営者に故意又は重大な過失がある場合を除き，運営者は一切の責任を負わないものとします。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第4条（禁止事項）
          </Typography>
          <Typography variant="body1">
            ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
          </Typography>
          <Typography variant="body1">
            (1)法令または公序良俗に違反する行為 犯罪行為に関連する行為
            (2)運営者，本サービスの他のユーザー，または第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
            (3)本サービスの運営を妨害するおそれのある行為
            (4)他のユーザーに関する個人情報等を収集または蓄積する行為
            (5)不正アクセスをし，またはこれを試みる行為
            (6)他のユーザーに成りすます行為
            (7)本サービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
            (8)運営者，本サービスの他のユーザーまたは第三者の知的財産権，肖像権，プライバシー，名誉その他の権利または利益を侵害する行為
            (9)以下の表現を含み，または含むと運営者が判断する内容を本サービス上に投稿し，または送信する行為
            (9.1)過度に暴力的な表現 露骨な性的表現
            (9.2)人種，国籍，信条，性別，社会的身分，門地等による差別につながる表現
            (9.3)自殺，自傷行為，薬物乱用を誘引または助長する表現
            (9.4)その他反社会的な内容を含み他人に不快感を与える表現
            (10)以下を目的とし，または目的とすると本サービスが判断する行為
            (10.1)営業，宣伝，広告，勧誘，その他営利を目的とする行為（本サービスの認めたものを除きます。）
            (10.2)性行為やわいせつな行為を目的とする行為
            (10.3)面識のない異性との出会いや交際を目的とする行為
            (10.4)他のユーザーに対する嫌がらせや誹謗中傷を目的とする行為
            (10.5)本サービス，本サービスの他のユーザー，または第三者に不利益，損害または不快感を与えることを目的とする行為
            (10.6)その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
            (11)宗教活動または宗教団体への勧誘行為
            (12)その他，運営者が不適切と判断する行為
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第5条（本サービスの提供の停止等）
          </Typography>
          <Typography variant="body1">
            本サービスは，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
            (1)本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
            (2)地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
            (3)コンピュータまたは通信回線等が事故により停止した場合
            (4)その他，本サービスがサービスの提供が困難と判断した場合
          </Typography>
          <Typography variant="body1">
            本サービスは，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第6条（著作権）
          </Typography>
          <Typography variant="body1">
            ユーザーは，自ら著作権等の必要な知的財産権を有するか，または必要な権利者の許諾を得た文章，画像や映像等の情報に関してのみ，本サービスを利用し，投稿ないしアップロードすることができるものとします。
            ユーザーが本サービスを利用して投稿ないしアップロードした文章，画像，映像等の著作権については，当該ユーザーその他既存の権利者に留保されるものとします。ただし，運営者は，本サービスを利用して投稿ないしアップロードされた文章，画像，映像等について，本サービスの改良，品質の向上，または不備の是正等ならびに本サービスの周知宣伝等に必要な範囲で利用できるものとし，ユーザーは，この利用に関して，著作者人格権を行使しないものとします。
            前項本文の定めるものを除き，本サービスおよび本サービスに関連する一切の情報についての著作権およびその他の知的財産権はすべて運営者または運営者にその利用を許諾した権利者に帰属し，ユーザーは無断で複製，譲渡，貸与，翻訳，改変，転載，公衆送信（送信可能化を含みます。），伝送，配布，出版，営業使用等をしてはならないものとします。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第7条（利用制限および登録抹消）
          </Typography>
          <Typography variant="body1">
            運営者は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，投稿データを削除し，ユーザーに対して本サービスの全部もしくは一部の利用を制限しまたはユーザーとしての登録を抹消することができるものとします。
          </Typography>
          <Typography variant="body1">
            (1)本規約のいずれかの条項に違反した場合
            (2)登録事項に虚偽の事実があることが判明した場合
            (3)運営者からの連絡に対し，一定期間返答がない場合
            (4)本サービスについて，最終の利用から一定期間利用がない場合
            (5)その他，運営者が本サービスの利用を適当でないと判断した場合
          </Typography>
          <Typography variant="body1">
            前項各号のいずれかに該当した場合，ユーザーは，当然に運営者に対する一切の債務について期限の利益を失い，その時点において負担する一切の債務を直ちに一括して弁済しなければなりません。
            運営者は，本条に基づき運営者が行った行為によりユーザーに生じた損害について，一切の責任を負いません。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第8条（退会）
          </Typography>
          <Typography variant="body1">
            ユーザーは，運営者の定める退会手続により，本サービスから退会できるものとします。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第9条（保証の否認および免責事項）
          </Typography>
          <Typography variant="body1">
            運営者は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
            運営者は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。
            前項ただし書に定める場合であっても，運営者は，運営者の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（運営者またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。
          </Typography>
          <Typography variant="body1">
            運営者は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第10条（サービス内容の変更等）
          </Typography>
          <Typography variant="body1">
            運営者は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第11条（利用規約の変更）
          </Typography>
          <Typography variant="body1">
            運営者は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第12条（個人情報の取扱い）
          </Typography>
          <Typography variant="body1">
            運営者は，本サービスの利用によって取得する個人情報については，「プライバシーポリシー」に従い適切に取り扱うものとします。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第13条（通知または連絡）
          </Typography>
          <Typography variant="body1">
            ユーザーと運営者との間の通知または連絡は，運営者の定める方法によって行うものとします。運営者は,ユーザーから,運営者が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第14条（権利義務の譲渡の禁止）
          </Typography>
          <Typography variant="body1">
            ユーザーは，運営者の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
          </Typography>
          <Typography variant="h4" component="h5" className={classes.header}>
            第15条（準拠法・裁判管轄）
          </Typography>
          <Typography variant="body1">
            本規約の解釈にあたっては，日本法を準拠法とします。
          </Typography>
        </Paper>

        <Paper className={classes.root} elevation={3}>
          <Typography variant="h4" component="h5" className={classes.header}>
            プライバシーポリシー
          </Typography>
          <Typography variant="body1">
            本サービスでは以下のようにプライバシーポリシーを定めています。サイトを利用するにはこのプライバシーポリシーについて同意する必要があります。
          </Typography>
          <Typography
            variant="h5"
            component="h5"
            className={classes.headerMiddle}
          >
            登録情報について
          </Typography>
          <Typography variant="body1">
            当サイトでは登録に必要な「メールアドレス」などの情報を保存しています。これらはサイト利用の範囲内でサイト管理者並びにサイト管理者と契約関係にあるパートナー社（広告配信、アクセス解析など）の間で利用されます。
          </Typography>
          <Typography
            variant="h5"
            component="h5"
            className={classes.headerMiddle}
          >
            広告の配信について
          </Typography>
          <Typography variant="body1">
            当サイトではGoogle及びGoogleのパートナー（第三者配信事業者）をはじめとした他者の広告サービスを設置しております。その広告配信にはCookieを使用し、当サイトへの過去のアクセス情報に基づいて広告を配信します。
          </Typography>
          <Typography variant="body1">
            このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当サイトや他サイトへのアクセスに関する情報
            『Cookie』(氏名、住所、メール アドレス、電話番号は含まれません)
            を使用することがあります。
          </Typography>
          <Typography variant="body1">
            またGoogleアドセンスに関して、このプロセスの詳細やこのような情報が広告配信事業者に使用されないようにする方法については、
            <a
              href="https://policies.google.com/technologies/ads?hl=ja"
              target="_blank"
              rel="noreferrer noopener"
            >
              こちら
            </a>
            をクリックしてください。
          </Typography>
        </Paper>
      </div>
    </div>
  )
}

class Terms extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    return <TermInfo />
  }
}

export default Terms
