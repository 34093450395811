import React from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import dressService, { DressDoc } from '../../service/dress-service'
import CircularProgress from '@material-ui/core/CircularProgress'
import DressImageCard from '../../component/DressImageCard'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { DressCategories, DressSellable } from '../../component/DressEnum'
import InputRange from '../../component/InputRange'
import SelectChip from '../../component/SelectChip'
import userService, { UserDoc } from '../../service/user-service'
import { User, getAuth, onIdTokenChanged } from 'firebase/auth'
import FileAttachButton from '../../component/FileAttachButton'
import storageService from '../../service/storage-service'

interface P extends RouteComponentProps {
  user: User | null
}
interface S {
  uploading: boolean
  photoUploading: boolean
  value: string
  photoURL: string
  uploaded: boolean
  canRegist: boolean

  imgUrl1: string
  imgUrl2: string
  imgUrl3: string
  imgUrl4: string

  title: string
  description: string
  maker: string
  accesory: string
  category: string
  lengthMax: number
  lengthMin: number
  color: string[]
  sellable: string
}

const styles = makeStyles((theme) => ({
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}))

class RegisterDressScreen extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      uploading: false,
      photoUploading: false,
      uploaded: false,
      canRegist: true,

      value: 'Sample text',
      photoURL: '',

      title: '',
      imgUrl1: '',
      imgUrl2: '',
      imgUrl3: '',
      imgUrl4: '',
      description: '',
      maker: '',
      accesory: '',
      category: 'Standard',
      lengthMax: 165,
      lengthMin: 155,
      color: [],
      sellable: 'disable',
    }
  }

  componentDidMount() {
    if (!this.props.user!.uid) {
      return
    }
    const auth = getAuth()
    onIdTokenChanged(auth, (user) => {
      if (user) {
        if (!user.emailVerified) {
          this.props.history.push('/user/mailverification')
        } else {
          const userUid = user.uid
          userService.getV2(userUid).then((userDoc?: UserDoc) => {
            if (!userDoc?.university) {
              this.setState({ canRegist: false })
            }
          })
        }
      } else {
        this.props.history.push('/')
      }
    })
  }

  FormWrapper = (props: { children: React.ReactNode }) => {
    const classes = styles()
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>{props.children}</Paper>
      </main>
    )
  }

  private onSubmit = async (event: any) => {
    event.preventDefault()
    const {
      title,
      description,
      category,
      imgUrl1,
      imgUrl2,
      imgUrl3,
      imgUrl4,
      maker,
      accesory,
      lengthMax,
      lengthMin,
      color,
      sellable,
    } = this.state
    if (!title || !category) {
      return
    }
    if (!imgUrl1 && !imgUrl2 && !imgUrl3 && !imgUrl4) {
      alert('最低1枚の写真をアップロードしてください')
      return
    }

    if (color.length >= 3) {
      alert('ドレスカラーは2色まで入力可能です')
      return
    }
    if (color.length === 0) {
      alert('ドレスカラーを1色または2色選択してください')
      return
    }
    const dress: DressDoc = {
      title,
      description,
      category,
      imgUrl1,
      imgUrl2,
      imgUrl3,
      imgUrl4,
      maker,
      accesory,
      lengthMax,
      lengthMin,
      color,
      sellable,
    }
    this.setState({ uploading: true })
    await dressService.regist(dress, this.props.user!).then((res) => {
      if (res) {
        alert('登録しました')
        this.setState({ uploaded: true, uploading: false })
      } else {
        alert('登録に失敗しました。時間を置いて、再度試して下さい。')
      }
    })
  }

  private onChangeImage1 = async (event: any) => {
    this.setState({ photoUploading: true })
    const files: any[] = event.target.files
    const url = await storageService.uploadImage(files[0], 1)
    this.setState({
      photoUploading: false,
      imgUrl1: url ? url : this.state.imgUrl1,
    })
  }

  private onChangeImage2 = async (event: any) => {
    this.setState({ photoUploading: true })
    const files: any[] = event.target.files
    const url = await storageService.uploadImage(files[0], 2)
    this.setState({
      photoUploading: false,
      imgUrl2: url ? url : this.state.imgUrl2,
    })
  }

  private onChangeImage3 = async (event: any) => {
    this.setState({ photoUploading: true })
    const files: any[] = event.target.files
    const url = await storageService.uploadImage(files[0], 3)
    this.setState({
      photoUploading: false,
      imgUrl3: url ? url : this.state.imgUrl3,
    })
  }

  private onChangeImage4 = async (event: any) => {
    this.setState({ photoUploading: true })
    const files: any[] = event.target.files
    const url = await storageService.uploadImage(files[0], 4)
    this.setState({
      photoUploading: false,
      imgUrl4: url ? url : this.state.imgUrl4,
    })
  }

  private changeTitle = (e: any) => {
    const value: string = e.target.value
    this.setState({ title: value })
  }
  private changeDescription = (e: any) => {
    const value: string = e.target.value
    this.setState({ description: value })
  }
  private changeMaker = (e: any) => {
    const value: string = e.target.value
    this.setState({ maker: value })
  }
  private changeAccesory = (e: any) => {
    const value: string = e.target.value
    this.setState({ accesory: value })
  }
  private changeCategory = (e: any) => {
    const value: string = e.target.value
    this.setState({ category: value })
  }

  private changeLengthSlider = (value: number[]) => {
    const min = Math.min(value[0], value[1])
    const max = Math.max(value[0], value[1])
    this.setState({ lengthMax: max, lengthMin: min })
  }

  private changeColor = (value: string[]) => {
    this.setState({ color: value })
  }
  private changeSellable = (e: any) => {
    const value: string = e.target.value
    this.setState({ sellable: value })
  }

  render() {
    return (
      <this.FormWrapper>
        <Typography component="h1" variant="h4" align="center">
          ドレス登録
        </Typography>
        <React.Fragment>
          <form onSubmit={this.onSubmit}>
            {this.state.canRegist && !this.state.uploaded && (
              <React.Fragment>
                <Typography variant="h6" gutterBottom>
                  最大4枚まで画像を載せることが出来ます
                </Typography>
                <Typography variant="body1" gutterBottom color="secondary">
                  ドレスの写真は、必ず自身で撮影したものを使用してください
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {this.state.imgUrl1 && (
                      <DressImageCard imgUrl={this.state.imgUrl1} />
                    )}
                    <FileAttachButton
                      text={
                        this.state.imgUrl1
                          ? '画像1を変更'
                          : '画像1をアップロード'
                      }
                      onChange={this.onChangeImage1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {this.state.imgUrl2 && (
                      <DressImageCard imgUrl={this.state.imgUrl2} />
                    )}
                    <FileAttachButton
                      text={
                        this.state.imgUrl2
                          ? '画像2を変更'
                          : '画像2をアップロード'
                      }
                      onChange={this.onChangeImage2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {this.state.imgUrl3 && (
                      <DressImageCard imgUrl={this.state.imgUrl3} />
                    )}
                    <FileAttachButton
                      text={
                        this.state.imgUrl3
                          ? '画像3を変更'
                          : '画像3をアップロード'
                      }
                      onChange={this.onChangeImage3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {this.state.imgUrl4 && (
                      <DressImageCard imgUrl={this.state.imgUrl4} />
                    )}
                    <FileAttachButton
                      text={
                        this.state.imgUrl4
                          ? '画像4を変更'
                          : '画像4をアップロード'
                      }
                      onChange={this.onChangeImage4}
                    />
                  </Grid>
                  {this.state.photoUploading && <CircularProgress />}
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="title"
                      name="title"
                      label="タイトル"
                      helperText="10～30文字程度で記入してください"
                      value={this.state.title}
                      onChange={this.changeTitle}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="description"
                      name="description"
                      label="説明"
                      helperText="1000文字以内で記入してください"
                      value={this.state.description}
                      onChange={this.changeDescription}
                      multiline
                      fullWidth
                      rows="4"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="maker"
                      name="maker"
                      label="メーカー"
                      helperText="例：チャコット"
                      value={this.state.maker}
                      onChange={this.changeMaker}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="accesory"
                      name="accesory"
                      label="付属品"
                      helperText="例：ネックレス、アームバングル"
                      value={this.state.accesory}
                      onChange={this.changeAccesory}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="select-ml"
                      select
                      label="スタンダード/ラテン"
                      fullWidth
                      value={this.state.category}
                      onChange={this.changeCategory}
                      margin="normal"
                    >
                      {DressCategories.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputRange callback={this.changeLengthSlider} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectChip callback={this.changeColor} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="select-sellable"
                      select
                      label="販売可/不可"
                      helperText="レンタル以外にも販売を検討している場合は「販売可」を選択可能です"
                      fullWidth
                      value={this.state.sellable}
                      onChange={this.changeSellable}
                      margin="normal"
                    >
                      {DressSellable.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            {this.state.canRegist && this.state.uploaded && (
              <Typography variant="body1" align="center">
                ドレスの登録が完了しました。
              </Typography>
            )}
            {!this.state.canRegist && (
              <Typography variant="body1" align="center" style={{ margin: 8 }}>
                ドレスの登録にはプロフィールの設定を完了させる必要があります
              </Typography>
            )}
            <div>
              {this.state.canRegist && !this.state.uploaded && (
                <>
                  <Typography variant="body1">
                    初めてドレスを登録する場合は
                    <Link to="/about">はじめに</Link>
                    を必ずご確認ください。また、
                    <Link to="/terms">利用規約</Link>
                    および<Link to="/terms">プライバシーポリシー</Link>
                    に同意の上、「登録」ボタンを押してください。
                  </Typography>
                  <Button
                    disabled={this.state.uploading || this.state.uploaded}
                    variant="contained"
                    type="submit"
                    style={{ marginTop: 20, backgroundColor: '#DBD1E8' }}
                  >
                    登録
                  </Button>
                </>
              )}
              {this.state.uploaded && (
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <Button size="small" color="primary">
                    トップページへ戻る
                  </Button>
                </Link>
              )}
              {!this.state.canRegist && (
                <Link
                  to="/user/edit/profile"
                  style={{ textDecoration: 'none' }}
                >
                  <Button size="small" color="primary">
                    プロフィールの設定へ
                  </Button>
                </Link>
              )}
            </div>
            <div>{this.state.uploading && <CircularProgress />}</div>
          </form>
        </React.Fragment>
      </this.FormWrapper>
    )
  }
}

export default withRouter(RegisterDressScreen)
