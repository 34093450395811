import { auth, db } from './service'
import dressService, { DressDoc } from './dress-service'
import {
  serverTimestamp,
  FieldValue,
  updateDoc,
  doc,
  setDoc,
  getDoc,
  orderBy,
  query,
  collection,
  getDocs,
} from 'firebase/firestore'
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  verifyBeforeUpdateEmail,
} from 'firebase/auth'

export interface UserDoc {
  id?: string // docId
  userUid?: string // authのuuid
  displayName?: string
  university?: string
  grade?: string
  prefecture?: string
  email?: string
  createdAt?: FieldValue
  updatedAt?: FieldValue
  // userUidの修正したら2
  // マイグレーション後に作られたら3
  ver?: number
}

const actionCodeSettings = {
  url: 'https://dressboard.work/',
  // url: 'http://localhost:3000/',
  handleCodeInApp: false,
}

const userService = {
  // Duplicated uidとuserUidの不整合
  // regist: async (user: UserDoc) => {
  //   const docRef = await addDoc(usersRef, {
  //     ...user,
  //     createdAt: serverTimestamp(),
  //   })
  //   return docRef.id
  // },

  registNewUser: async () => {
    const authUser = auth.currentUser
    const uid = authUser?.uid
    // @ts-ignore
    await setDoc(doc(db, 'users', uid), {
      id: uid,
      userUid: uid,
      ver: 3,
      email: auth.currentUser?.email,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    })
    return
  },

  // Duplicated uidとuserUidの不整合
  // registByEmail: async (email: String) => {
  //   const userAuth = auth.currentUser
  //   const user = {
  //     email,
  //     userUid: userAuth?.uid,
  //   }
  //   const docRef = await addDoc(usersRef, {
  //     ...user,
  //     createdAt: serverTimestamp(),
  //   })
  //   return docRef.id
  // },

  update: async (user: UserDoc) => {
    const selfResult = await userService.updateSelf(user)
    // authで登録してるけどfirebaseに登録してないアカウント向けの不整合解消　今後作った垢なら通らない
    if (!selfResult) {
      const userAuth = auth.currentUser
      const email = userAuth!.email ? userAuth!.email : ''
      user = {
        ...user,
        email,
      }
      await userService.registNewUser()
    }
    const dressResult = await userService.updateOwnDresses(user)
    return dressResult
  },

  updateSelf: async (user: UserDoc) => {
    //@ts-ignore
    const userRef = doc(db, 'users', user.id)
    // console.log('user =', user)
    return await updateDoc(userRef, {
      ...user,
      updatedAt: serverTimestamp(),
    })
      .then(() => {
        return true
      })
      .catch((e: any) => {
        console.log(e)
        return null
      })
  },

  updateOwnDresses: async (user: UserDoc) => {
    const dresses: DressDoc[] | undefined = await dressService.getOwnDress(
      user.userUid!
    )
    if (!dresses || dresses.length === 0) return true
    let success = true
    for (const dress of dresses) {
      //@ts-ignore
      const dressRef = doc(db, 'dresses', dress.dressId)
      await updateDoc(dressRef, {
        ...dress,
        ownerUniv: user.university,
        ownerGrade: user.grade,
        updatedAt: serverTimestamp(),
      }).catch((e: any) => {
        console.log(e)
        success = false
      })
    }
    return success
  },

  updateEmail: async (
    user: UserDoc,
    prevEmail: string,
    currentEmail: string,
    password: string
  ) => {
    const auth = getAuth()
    const currentUser = auth.currentUser
    if (!currentUser) {
      return
    }
    const credential = EmailAuthProvider.credential(prevEmail, password)
    await reauthenticateWithCredential(currentUser, credential)
    const updateResult = await verifyBeforeUpdateEmail(
      currentUser,
      currentEmail,
      actionCodeSettings
    )
      .then(() => {
        return true
      })
      .catch((e: any) => {
        return false
      })
    if (!updateResult) return false
    user.email = currentEmail
    //TODO 確認メールをスルーした時に問い合わせメール送信で不一致起こすのが問題
    return await userService.updateSelf(user)
  },

  // ver 2以降のdocならidとuserUidとauthのuidは一致しているのでこっちを使う
  getV2: async (id: string) => {
    const docRef = doc(db, 'users', id)
    const docSnap = await getDoc(docRef)
    if (!docSnap.exists()) {
      console.log('No user!')
      return undefined
    }
    return docSnap.data()
  },
  getFavorites: async (userUid: string) => {
    const collectionRef = collection(db, 'users', userUid, 'favorites')
    const q = query(collectionRef, orderBy('createdAt', 'desc'))
    const querySnapshot = await getDocs(q)
    const dresses: any[] = []
    querySnapshot.forEach((doc) => {
      const dressDoc = doc.data()
      dressDoc.dressId = doc.id
      dresses.push(dressDoc)
    })
    return dresses
  },
  getFavorite: async (userUid: string, dressId: string) => {
    const docRef = doc(db, 'users', userUid, 'favorites', dressId)
    const docSnap = await getDoc(docRef)
    if (!docSnap.exists()) {
      console.log('Dress is not favorited.')
      return undefined
    }
    return docSnap.data()
  },
}
export default userService
