import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { DressDoc } from '../../service/dress-service'
import DressCard from '../../component/DressCard'
import DressListNone from '../../component/DressListNone'
import DressCardSkelton from '../../component/DressCardSkelton'
import { DressListPagination } from '../../component/DressListPagination'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import userService from '../../service/user-service'
import { User } from 'firebase/auth'

interface P extends RouteComponentProps<{ page: string }> {
  user: User | null
}

interface S {
  dresses: DressDoc[]
  dressesDisplayed: DressDoc[]
  firstLoading: boolean
  pageCount: number
  page: number
}

const ITEMS_PER_PAGE = 12

const sliceDressesDisplayed = (page: number, dresses: DressDoc[]) => {
  return dresses.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
}

const calcPageCount = (dressesLength: number) => {
  return ((dressesLength / ITEMS_PER_PAGE) | 0) + 1
}
const getPage = (location: any) => {
  let page = location.state && location.state.page
  if (page === undefined) {
    page = 1
  }
  return page
}

const returnTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  header: {
    paddingTop: theme.spacing(2),
  },
}))

const FirstLoading = () => {
  const classes = useStyles()
  const skeltons = Array.from(new Array(ITEMS_PER_PAGE))
  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Grid container spacing={4}>
        {skeltons.map((item, index) => (
          <DressCardSkelton key={index} />
        ))}
      </Grid>
    </Container>
  )
}

const ListHeader = (props: { text: string }) => {
  const classes = useStyles()
  return (
    <Typography component="h4" variant="h5" className={classes.header}>
      {props.text}
    </Typography>
  )
}

const DressListContents = (props: { cards: DressDoc[] }) => {
  const classes = useStyles()
  if (!props.cards || props.cards.length === 0) {
    return <DressListNone />
  }
  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Grid container spacing={4}>
        {props.cards.map((card) => (
          <DressCard card={card} key={card.dressId} />
        ))}
      </Grid>
    </Container>
  )
}

class FavoriteListPagenationScreen extends React.Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      dresses: [],
      dressesDisplayed: [],
      firstLoading: true,
      pageCount: 10,
      page: getPage(props.location),
    }
  }

  componentDidMount() {
    this.filter()
  }

  private filter = async () => {
    const dresses = await this.getFilteredDresses()
    this.updateDisplayedAndTotalDresses(dresses)
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      state: {
        page: this.state.page,
      },
    })
  }

  private getFilteredDresses = async () => {
    let dresses: DressDoc[] = []
    dresses = await userService.getFavorites(this.props.user?.uid!)
    dresses = dresses.filter((dress) => dress.isDeleted !== true)
    return dresses
  }

  private updateDisplayedAndTotalDresses = (dresses: DressDoc[]) => {
    const { page } = this.state
    this.setState({
      pageCount: calcPageCount(dresses.length),
      dresses: dresses,
      dressesDisplayed: sliceDressesDisplayed(page, dresses),
      firstLoading: false,
    })
  }

  private changePage = (page: number) => {
    const { dresses } = this.state
    this.setState({
      page,
      dressesDisplayed: sliceDressesDisplayed(page, dresses),
    })
    returnTop()
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      state: {
        page: page,
      },
    })
  }

  render() {
    const dressesDisplayed: DressDoc[] = this.state.dressesDisplayed
    const { firstLoading, pageCount, page } = this.state
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <ListHeader text={'お気に入り一覧'} />
          {firstLoading && <FirstLoading />}
          {!firstLoading && <DressListContents cards={dressesDisplayed} />}
          <DressListPagination
            pageCount={pageCount}
            defaultPage={page}
            changePage={this.changePage}
          />
        </main>
      </React.Fragment>
    )
  }
}

export default withRouter(FavoriteListPagenationScreen)
